'use client';
import React, { useState, useRef } from 'react';
import parse from 'html-react-parser';
import { RecordAttributes } from '@next-app/interface/NonProductResults';
import Link from '@next-app/components/elements/Link/Link';
import { searchTabsConstant } from '@next-app/constants/constants';
import Conditional from '@next-app/components/Conditional';
import DynamicModal from '@next-app/components/elements/DynamicModal/DynamicModal';
import { isNotNullOrUndefined } from '@next-app/utils/common-utils';
import { addTrailingSlash } from '@next-app/utils/common-utils';
import classes from '../NonProductResults/non-product-result.module.scss';
import Image from 'next/image';

const VideoResultList = ({ records, imgBaseUrl }: RecordAttributes) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const handlePlayVideo = (sceneId: string, baseContentUrl: string) => {
    const videoUrl = `${baseContentUrl}${searchTabsConstant.VIDEO}${sceneId}_Tablet_Autox480p_1400k.mp4`;
    setCurrentVideoUrl(videoUrl);
    setShowVideoModal(true);
  };
  const handleVideoCanPlay = () => {
    if (videoRef.current && isNotNullOrUndefined(videoRef.current.play)) {
      videoRef.current.play();
    }
  };

  return (
    <>
      {records.map((record, index) => {
        const description = record.attributes['mediaContent.description']?.[0];
        const title = record.attributes['mediaContent.name']?.[0];
        const maxGrade = record.attributes['mediaContent.maxGrade']?.[0] ?? '';
        const minGrade = record.attributes['mediaContent.minGrade']?.[0] ?? '';
        const repositoryId: string =
          record.attributes['mediaContent.repositoryId']?.[0] ?? '';
        const seoUrl = record.attributes['mediaContent.seoUrl']?.[0] ?? '';
        const baseContentUrl =
          record.attributes['mediaContent.baseContentUrl']?.[0] ?? '';
        const viewProductUrl = searchTabsConstant.VIDEO_URL + '' + seoUrl;

        const linkUrl = addTrailingSlash(viewProductUrl);

        const sceneId =
          record.attributes['mediaContent.mediaDisplayImage']?.[0] ?? '';
        const thumbnailCard = `${imgBaseUrl}/${sceneId}_tn`;

        return (
          <div
            key={repositoryId}
            className={`${classes['search-item-list-view']}`}
          >
            <div
              className={`${classes['search-item-image']} ${classes['video']}`}
            >
              <Link
                className={classes['play-video']}
                data-bs-toggle="modal"
                data-testid="play-video-link"
                data-bs-target="#dynamicModalWrapper"
                onClick={() => handlePlayVideo(sceneId, baseContentUrl)}
              >
                <Image
                  src={thumbnailCard}
                  alt=""
                  width="180"
                  height="135"
                  loading={index < 4 ? 'eager' : 'lazy'} // Eager for the first four, lazy for the rest
                />
              </Link>
            </div>

            <div className={`${classes['search-item-content']}`}>
              <Link className={classes['search-title']} href={linkUrl}>
                {parse(title || '')}
              </Link>
              <div data-testid="grade-display">
                {minGrade === maxGrade ? minGrade : `${minGrade} - ${maxGrade}`}
              </div>
              <p>{parse(description || '')}</p>

              <Link
                className={`${classes['play-video']} ${classes['link']}`}
                data-bs-toggle="modal"
                data-bs-target="#dynamicModalWrapper"
                onClick={() => handlePlayVideo(sceneId, baseContentUrl)}
              >
                Play Video
              </Link>

              <Conditional if={showVideoModal}>
                <DynamicModal
                  modalContentClass={`ll-modal-md ll-modal-top ${classes['play-video-wrapper']}`}
                  modalTitle=""
                  onModalClose={() => {
                    setShowVideoModal(false);
                    setCurrentVideoUrl(null);
                  }}
                >
                  {currentVideoUrl && (
                    <video
                      ref={videoRef}
                      controls
                      className={classes['video-player']}
                      src={currentVideoUrl}
                      data-testid="video-element"
                      onCanPlay={handleVideoCanPlay}
                      controlsList="nodownload"
                    ></video>
                  )}
                </DynamicModal>
              </Conditional>
              <Link
                data-testid="record-url"
                className={classes['view-details']}
                href={linkUrl}
              >
                View Product
              </Link>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default VideoResultList;
