'use client';
import Link from '@next-app/components/elements/Link/Link';
import classes from './collapsible.module.scss';

interface CollapsibleLinkProps {
  id: string;
}

const CollapsibleLink = ({ id }: CollapsibleLinkProps) => {
  return (
    <Link
      className={`${classes['read-text-button']} ${classes['morelink']} ${classes['nowrap']} less`}
      href="#"
      data-bs-toggle="collapse"
      data-bs-target={`#collapseReadMoreProdDesc-${id}`}
      aria-controls={`collapseReadMoreProdDesc-${id}`}
    >
      <i
        className={`${classes['icn-caret-rgt']} ls-icon red lh22`}
        aria-hidden="true"
      ></i>
    </Link>
  );
};

export default CollapsibleLink;
