'use client';

import Paginations from '../Pagination/Pagination';
import classes from './product-card.module.scss';
import { useRouter } from 'next/navigation';
import { BaseSyntheticEvent, useEffect } from 'react';
import { IProductCardHandlerProps, ISortItems } from '@next-app/interface/PLP';
import redirectionPath from '@next-app/utils/pathRedirectionAndScroll';
import { setCookie } from '@next-app/utils/cookies';
import {
  COOKIE_EXPIRY_TIME,
  RecsPerPage,
  SortBy,
} from '@next-app/constants/constants';
import { dyEvent } from '@next-app/utils/dy-event';
import Conditional from '@next-app/components/Conditional';
const ProductCardHandler = ({
  sortOptions,
  items,
  totalNumRecs,
  recsPerPage,
  lastRecNum,
  filtersLength,
  pagingActionTemplate,
  availabilityCount,
  recordType,
}: IProductCardHandlerProps) => {
  const router = useRouter();
  const handleRoute = (path: string) => {
    redirectionPath(router, path);
  };

  const handleSort = (event: BaseSyntheticEvent) => {
    const val = event.target.value;
    const properties = {
      dyType: 'sort-items-v1',
      sortBy: val,
      sortOrder: 'ASC',
    };
    dyEvent({ properties: properties, eventName: 'Sort Items' });

    setCookie('sort', SortBy[val], COOKIE_EXPIRY_TIME);
    const item = sortOptions.find((item) => item.label === val) || {
      link: '',
    };
    handleRoute(item?.link ?? '');
  };

  const handleItems = (event: BaseSyntheticEvent) => {
    const val = event.target.value;
    setCookie('num', RecsPerPage[val]?.toString(), COOKIE_EXPIRY_TIME);
    const item = items.find((item) => item.label === val) || { link: '' };
    handleRoute(item?.link ?? '');
  };

  const selectedSortVal = sortOptions.find((item) => item.selected);
  const selectedPageVal = items.find((item) => item.selected);

  useEffect(() => {
    setCookie('num', recsPerPage.toString(), COOKIE_EXPIRY_TIME);
    const selectedSort = sortOptions.find((item: ISortItems) => item.selected);

    if (selectedSort) {
      setCookie('grid', SortBy[selectedSort.label], COOKIE_EXPIRY_TIME);
    } else {
      setCookie('sort', 'best', COOKIE_EXPIRY_TIME);
    }
  }, []);

  const sortOptionsData = sortOptions.map((item: ISortItems, index: number) => {
    return (
      <option
        key={index}
        value={item.label}
        className={classes['handler-dropdown']}
      >
        {item.label}
      </option>
    );
  });

  const pageOptions = items.map((item: ISortItems, index: number) => {
    return (
      <option
        key={index}
        value={item.label}
        className={classes['handler-dropdown']}
      >
        {item.label}
      </option>
    );
  });

  return (
    <div
      className={`row ${classes['product-card-handler']} ${filtersLength ? classes['active-filter'] : ''}`}
    >
      <div
        className={`col-md-4 ${classes['sort-handler']} ${availabilityCount === 0 ? classes['avaialbility-bottom'] : ''}`}
      >
        {/* Added condition to show sort filter for products only  */}
        <Conditional if={recordType === 'sku'}>
          <>
            <span className={`${classes['filter-text']}`}></span>
            <select onChange={handleSort} defaultValue={selectedSortVal?.label}>
              {sortOptionsData}
            </select>
            <span className={`${classes['caret-select']}`}></span>
          </>
        </Conditional>
      </div>

      <div className={`col-md-4 ${classes['item-handler']} text-center`}>
        <Conditional if={totalNumRecs > 24}>
          <>
            <span>ITEMS:</span>
            <select
              onChange={handleItems}
              defaultValue={selectedPageVal?.label}
            >
              {pageOptions}
            </select>
            <span className={`${classes['caret-select']}`}></span>
          </>
        </Conditional>
      </div>
      <div className={`col-md-4 ${classes['top-side-pagination']}`}>
        <Paginations
          totalNumRecs={totalNumRecs}
          recsPerPage={recsPerPage}
          lastRecNum={lastRecNum}
          pagingActionTemplate={pagingActionTemplate}
        />
      </div>
    </div>
  );
};

export default ProductCardHandler;
