import { createSlice } from '@reduxjs/toolkit';
import { PDPBreadCrumbsContent } from '@next-app/interface/PDP/PDPBreadCrumbs';
import { PDPMetaTagValues } from '@next-app/interface/PDP/PDPMetaTagValues';
import {
  DELIVERY_METHODS,
  PDPLazyLoadContent,
} from '@next-app/interface/PDP/PDPPageContent';
import {
  PDPChildSKUInfo,
  PDPProductInfoContent,
} from '@next-app/interface/PDP/PDPProductInfo';
import { PDPReviewListContent } from '@next-app/interface/PDP/PDPReview';
import { filterSelectedSKU } from '@next-app/utils/PDPHelper';

const initialGiftCardDetails = {
  amount: '',
  recepientEmail: '',
  recepientName: '',
  userName: '',
  message: '',
  customAmount: '',
  giftCardType: '',
};

const initialState = {
  isChildSkuSelected: false,
  pdpMainContents: {},
  carouselMainContents: {},
  pdpMetaTagValues: {},
  selectedSkuId: '',
  selectedGiftCardDetails: { ...initialGiftCardDetails },
  selectedSku: {},
  personalization: {
    isPersonalized: false,
    message: '',
  },
  delivery: {
    method: '', //ship item or store pickup
  },
  selectedItemQty: {},
  productHasChildSku: false,
  parentSku: {},
  parentSkuId: '',
  dropdownValues: {},
  showDropdownError: false,
  showQtyError: '',
  showStoreError: '',
  productId: null,
  mboxid: '',
  webContentCarousel: null,
  storeOnlySelectStoreErrorMessage: false,
};

export const ProductSlice = createSlice({
  name: 'Products',
  initialState: {},
  reducers: {
    addProduct(state: any, action) {
      {
        const {
          pdpMainContents,
          pdpMetaTagValues,
        }: {
          pdpMainContents: [
            PDPBreadCrumbsContent,
            PDPProductInfoContent,
            PDPLazyLoadContent,
            PDPReviewListContent,
          ];
          pdpMetaTagValues: PDPMetaTagValues;
        } = action.payload;

        const productId = pdpMainContents[1].productId;
        const selectedSku =
          pdpMainContents[1]?.availableSkuOptions?.selectedSku || null;
        const hasChildSku =
          pdpMainContents[1]?.product?.childSKUS?.length > 1 || false;

        const parentSku = filterSelectedSKU(
          pdpMainContents[1],
          pdpMainContents[1].productId,
        );

        if (productId) {
          state[productId] = JSON.parse(JSON.stringify(initialState));
          state[productId].isChildSkuSelected = false;
          state[productId].pdpMainContents = pdpMainContents;
          state[productId].pdpMetaTagValues = pdpMetaTagValues;
          state[productId].selectedSku = selectedSku;
          state[productId].selectedSkuId = selectedSku?.id || '';
          state[productId].delivery.method = DELIVERY_METHODS.SHIP_ITEM;
          state[productId].selectedGiftCardDetails = {
            ...initialGiftCardDetails,
          };
          state[productId].productHasChildSku = hasChildSku;
          state[productId].parentSku = parentSku;
          state[productId].parentSkuId = parentSku?.id || '';
        }
      }
    },
    addAssessory(state: any, action) {
      const assessory = action.payload.item;
      const productId = assessory.product.repositoryId;
      const selectedSku = assessory?.availableSkuOptions?.selectedSku || null;
      const hasChildSku = assessory?.product?.childSKUS?.length > 1 || false;

      const parentSku = filterSelectedSKU(
        assessory,
        assessory.product.repositoryId,
      );

      if (productId) {
        console.log(state);
        state[productId] = JSON.parse(JSON.stringify(initialState));
        state[productId].isChildSkuSelected = false;
        state[productId].pdpMainContents = [{}, {}];
        state[productId].pdpMainContents[1] = assessory;
        state[productId].selectedSku = selectedSku;
        state[productId].selectedSkuId = selectedSku?.id || '';
        state[productId].delivery.method = DELIVERY_METHODS.SHIP_ITEM;
        state[productId].selectedGiftCardDetails = {
          ...initialGiftCardDetails,
        };
        state[productId].productHasChildSku = hasChildSku;
        state[productId].parentSku = parentSku;
        state[productId].parentSkuId = parentSku?.id || '';
      }
    },
    updateSelectChildSku(state: any, action) {
      const { productId, selectedSkuId, masterProductId } = action.payload;
      const filteredSku: PDPChildSKUInfo | null = filterSelectedSKU(
        state[productId].pdpMainContents[1],
        selectedSkuId,
      );
      state[productId].isChildSkuSelected = true;
      if (productId !== masterProductId)
        state[masterProductId].accessoriesSelectedSku = filteredSku;
      state[productId].selectedSku = filteredSku;
      state[productId].selectedSkuId = filteredSku?.id || '';
    },
    updatePersonalization(state: any, action) {
      const { productId, isPersonalized, message } = action.payload;
      if (isPersonalized) {
        state[productId].personalization.isPersonalized = isPersonalized;
      }
      if (message) {
        state[productId].personalization.message = message;
      }
    },
    addInventoryInfo(state: any, action) {
      const { productId, selectInventoryInfo } = action.payload;
      state[productId].selectInventoryInfo = selectInventoryInfo;
    },
    updateDeliveryMethod(state: any, action) {
      const { productId, method } = action.payload;
      state[productId].delivery.method = method;
    },
    updateInventoryExceeded(state: any, action) {
      const { productId, selectIsInventoryExceeded } = action.payload;
      state[productId]
        ? (state[productId].selectIsInventoryExceeded =
            selectIsInventoryExceeded)
        : null;
    },
    updateSelectedItemQty(state: any, action) {
      const { productId, selectedItemQty } = action.payload;
      state[productId]
        ? (state[productId].selectedItemQty[productId] = selectedItemQty)
        : null;
    },
    updateDropdownValues(state: any, action) {
      const { productId, dropdownValues } = action.payload;
      if (state[productId]) state[productId].dropdownValues = dropdownValues;
    },
    updateShowDropdownError(state: any, action) {
      const { productId, showDropdownError } = action.payload;
      state[productId]
        ? (state[productId].showDropdownError = showDropdownError)
        : null;
    },

    giftCardAmountUpdated(state: any, action) {
      const { productId, value } = action.payload;
      state[productId].selectedGiftCardDetails.customAmount = '';
      state[productId].selectedGiftCardDetails.amount = value;
    },
    updateGiftCardCustomAmount(state: any, action) {
      const { productId, amount } = action.payload;
      state[productId].selectedGiftCardDetails.customAmount = amount;
      if (state[productId].selectedGiftCardDetails.amount !== 'Custom Amount') {
        state[productId].selectedGiftCardDetails.amount = '';
      }
    },
    updateSelectedGiftCard(state: any, action) {
      const { productId } = action.payload;
      state[productId].selectedGiftCardDetails = {
        ...state[productId]?.selectedGiftCardDetails,
        ...action.payload,
      };
    },

    updateShowQtyError(state: any, action) {
      const { productId, showQtyError } = action.payload;
      state[productId] ? (state[productId].showQtyError = showQtyError) : null;
    },
    updateWebContentCarousel(state: any, action) {
      const { carouselData, productId } = action.payload;
      state[productId]
        ? (state[productId].webContentCarousel = carouselData)
        : null;
    },
    updateStoreError(state: any, action) {
      const { productId, showStoreError } = action.payload;
      state[productId]
        ? (state[productId].showStoreError = showStoreError)
        : null;
    },

    updateStoreOnlyStoreSelection(state: any, action) {
      const { productId, status } = action.payload;
      state[productId].storeOnlySelectStoreErrorMessage =
        state[productId] && status ? status : false;
    },
  },
});

export const {
  addProduct,
  addAssessory,
  updateSelectChildSku,
  updatePersonalization,
  addInventoryInfo,
  updateDeliveryMethod,
  updateInventoryExceeded,
  updateSelectedItemQty,
  updateDropdownValues,
  updateShowDropdownError,
  giftCardAmountUpdated,
  updateGiftCardCustomAmount,
  updateSelectedGiftCard,
  updateShowQtyError,
  updateStoreError,
  updateWebContentCarousel,
  updateStoreOnlyStoreSelection,
} = ProductSlice.actions;

export default ProductSlice.reducer;
