'use client';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrackConversion } from '@next-app/utils/common-utils';
import { isNotEmptyObject } from '../../ProductDetails/helper';

interface TrackConversionProps {
  event: string;
  pageTitle?: string;
}

const TrackConversionComponent = ({
  event,
  pageTitle,
}: TrackConversionProps) => {
  const { init, sessionInfo } = useSelector((state: any) => state.init);
  const { Products = {} } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const productId = (Products && Object.keys(Products)) || '';

  useEffect(() => {
    if (isNotEmptyObject(sessionInfo)) {
      const {
        cartInfo,
        dynSess: { dynSessConfNumber },
        profile: { id: profileId },
      } = sessionInfo.sessionInfo;

      TrackConversion({
        event,
        pageName: pageTitle || document.title,
        orderId: cartInfo?.orderId || '',
        trackConversionBaseUrl: init?.initResponse?.internalAPI,
        dynSessConfNumber: dynSessConfNumber,
        dispatch,
        profileId,
        cartId: cartInfo?.cartId || profileId,
        productId: (productId && productId[0]) || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo]);

  return null;
};

export default TrackConversionComponent;
