import PageOneColumn from '../components/shared/PageOneColumn/PageOneColumn';
import ContentSlot from '../components/shared/ContentSlot/ContentSlot';
import dynamic from 'next/dynamic';

import {
  AccountMenu,
  AccountMenuLink,
  AllCategoryNavigationMenu,
  AssortmentListing,
  AssortmentResultsList,
  Banner,
  Chiclet,
  ChicletsContainer,
  ContactForm,
  ContactFormField,
  DynamicCategoriesNavigationMenu,
  FeaturedSlot,
  FooterCertification,
  FooterLinkBlock,
  FooterLinkColumn,
  FullWidthImageWithButtons,
  ImageRow,
  JSON,
  LakeshoreIcon,
  Link,
  LinkFooter,
  MLink,
  MainMenu,
  MenuGroup,
  MenuGroupSlot,
  MenuItem,
  MenuLink,
  MenuTab,
  MenuTabSlot,
  MicroBanner,
  Modal,
  Navigation,
  PageContentButton,
  PageSlot,
  ProductRecs,
  ResultsList,
  RowImage,
  Search,
  Section,
  SectionFooter,
  SectionHTMLTag,
  SectionHeader,
  Slot,
  SocialIcons,
  SubscribeForm,
  ViewMoreText,
  StrictCategoryListingTitle,
  ProductsListing,
  Text,
  EyebrowMessage,
} from '../components/shared/PageContent';
const HeaderEyebrow = dynamic(
  () =>
    import(
      '@next-app/components/shared/PageContent/HeaderEyebrow/HeaderEyebrow'
    ),
);
import { Media } from '@next-app/components/shared/CarouselContent';
import { ComponentType } from 'react';

interface ComponentsMap {
  [key: string]: React.FC<any> | ComponentType<any>;
}

export const COMPONENTS_MAP: ComponentsMap = {
  'PageContent-AccountMenu': AccountMenu,
  'PageContent-AssortmentListing': AssortmentListing,
  'PageContent-AccountMenuLink': AccountMenuLink,
  'PageContent-AllCategoryNavigationMenu': AllCategoryNavigationMenu,
  'PageContent-AssortmentResultsList': AssortmentResultsList,
  'PageContent-Banner': Banner,
  'CarouselContent-Media': Media,
  'PageContent-Chiclet': Chiclet,
  'PageContent-ChicletsContainer': ChicletsContainer,
  ContentSlot: ContentSlot,
  'PageContent-ContactForm': ContactForm,
  'PageContent-ContactFormField': ContactFormField,
  'PageContent-DynamicCategoriesNavigationMenu':
    DynamicCategoriesNavigationMenu,
  'PageContent-EyebrowMessage': EyebrowMessage,
  'PageContent-FeaturedSlot': FeaturedSlot,
  'PageContent-FooterCertification': FooterCertification,
  'PageContent-FooterLinkBlock': FooterLinkBlock,
  'PageContent-FooterLinkColumn': FooterLinkColumn,
  'PageContent-FullWidthImageWithButtons': FullWidthImageWithButtons,
  'PageContent-Eyebrow': HeaderEyebrow,
  'PageContent-ImageRow': ImageRow,
  'PageContent-JSON': JSON,
  'PageContent-LakeshoreIcon': LakeshoreIcon,
  'PageContent-Link': Link,
  'PageContent-MLink': MLink,
  'PageContent-LinkFooter': LinkFooter,
  'PageContent-MainMenu': MainMenu,
  'PageContent-MenuTabSlot': MenuTabSlot,
  'PageContent-MenuTab': MenuTab,
  'PageContent-MenuGroup': MenuGroup,
  'PageContent-MenuGroupSlot': MenuGroupSlot,
  'PageContent-MenuItem': MenuItem,
  'PageContent-MenuLink': MenuLink,
  'PageContent-MicroBanner': MicroBanner,
  'PageContent-Modal': Modal,
  'PageContent-Navigation': Navigation,
  'PageContent-Button': PageContentButton,
  'Page-OneColumn': PageOneColumn,
  PageSlot: PageSlot,
  'PageContent-ProductListing': ProductsListing,
  'PageContent-ProductRecs': ProductRecs,
  'PageContent-RowImage': RowImage,
  'PageContent-ResultsList': ResultsList,
  'PageContent-Section': Section,
  'PageContent-SectionHeader': SectionHeader,
  'PageContent-Slot': Slot,
  'PageContent-Search': Search,
  'PageContent-SectionHTMLTag': SectionHTMLTag,
  'PageContent-SectionFooter': SectionFooter,
  'PageContent-SocialIcons': SocialIcons,
  'PageContent-SubscribeForm': SubscribeForm,
  'PageContent-StrictCategoryListingTitle': StrictCategoryListingTitle,
  'PageContent-Text': Text,
  'PageContent-ViewMoreText': ViewMoreText,
};
