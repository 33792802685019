import { Product } from '@next-app/interface/Common';
import { useSelector } from 'react-redux';
import classes from './search.module.scss';
import { formatUSPrice } from './helper';
import Link from '@next-app/components/elements/Link/Link';
import Image from 'next/image';
import { selectIsGSAUser } from '@next-app/lib/features/InitSelectors';
import { FetchRecsProduct } from '@next-app/interface/Common/api-response';

interface ProductListProp {
  products: Product[] | FetchRecsProduct[];
  isRecentResults?: boolean;
  customClass?: string;
}

export const ProductsList = ({
  products,
  isRecentResults = false,
  customClass,
}: ProductListProp) => {
  const { init } = useSelector((state: any) => state.init);
  const { initResponse: { scene7BaseURL = '' } = {} } = init || {};
  const gsaUser = useSelector(selectIsGSAUser);

  const getPriceDisplay = (ele: Product, isGsaUser: boolean) => {
    const { priceMax, saleMax, priceMin, saleMin, gsa } = ele;
    const isSalePrice = priceMax !== saleMax || priceMin !== saleMin;

    const handleRegPrice = (
      isSalePrice: boolean,
      priceMax: number,
      priceMin: number,
    ) => {
      return (
        <span
          className={`${classes.grey} ${isSalePrice ? classes['price-small'] : ''}`}
          style={{ display: 'block' }}
        >
          reg.{' '}
          {priceMax === priceMin
            ? formatUSPrice(priceMax)
            : `${formatUSPrice(priceMin)} - ${formatUSPrice(priceMax)}`}
        </span>
      );
    };

    switch (true) {
      case ele?.gcPriceRange && ele?.gcPriceRange !== '':
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {ele?.gcPriceRange}
          </span>
        );
      // Case for GSA user when sale price is fixed
      case isSalePrice && isGsaUser && gsa && saleMax === saleMin:
        return (
          <span className={classes.searchGsaUser}>
            {`${formatUSPrice(saleMax)} GSA`}
          </span>
        );

      // Case for GSA user when sale price is a range
      case isSalePrice && isGsaUser && gsa && saleMax !== saleMin:
        return (
          <span className={classes.searchGsaUser}>
            {`${formatUSPrice(saleMin)} - ${formatUSPrice(saleMax)} GSA`}
          </span>
        );

      // Case for GSA item but no GSA price (Show regular price)
      case gsa && (!isSalePrice || !isGsaUser):
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {priceMax === priceMin
              ? formatUSPrice(priceMax)
              : `${formatUSPrice(priceMin)} - ${formatUSPrice(priceMax)}`}
          </span>
        );
      // Case for GSA user when sale price is fixed
      case isSalePrice && isGsaUser && !gsa && saleMax === saleMin:
        return (
          <>
            <span
              className={classes.grey}
              style={{ display: 'block', color: 'red' }}
            >
              {formatUSPrice(saleMax)}
            </span>
            {handleRegPrice(isSalePrice, priceMax, priceMin)}
          </>
        );

      // Case for GSA user when sale price is a range
      case isSalePrice && isGsaUser && !gsa && saleMax !== saleMin:
        return (
          <>
            <span
              className={classes.grey}
              style={{ display: 'block', color: 'red' }}
            >
              {`${formatUSPrice(saleMin)} - ${formatUSPrice(saleMax)}`}
            </span>
            {handleRegPrice(isSalePrice, priceMax, priceMin)}
          </>
        );

      // Case for non-GSA user when sale price is fixed
      case isSalePrice && !isGsaUser && !gsa && saleMax === saleMin:
        return (
          <>
            <span
              className={classes.grey}
              style={{ display: 'block', color: 'red' }}
            >
              {formatUSPrice(saleMax)}
            </span>
            {handleRegPrice(isSalePrice, priceMax, priceMin)}
          </>
        );

      // Case for non-GSA user when sale price is a range
      case isSalePrice && !isGsaUser && !gsa && saleMax !== saleMin:
        return (
          <>
            <span
              className={classes.grey}
              style={{ display: 'block', color: 'red' }}
            >
              {`${formatUSPrice(saleMin)} - ${formatUSPrice(saleMax)}`}
            </span>
            {handleRegPrice(isSalePrice, priceMax, priceMin)}
          </>
        );

      // Non-GSA, no sale, and priceMin is not equal to priceMax
      case !gsa && !isSalePrice && priceMin !== priceMax:
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {`${formatUSPrice(priceMin)} - ${formatUSPrice(priceMax)}`}
          </span>
        );

      // Non-GSA, no sale, and priceMin is equal to priceMax
      case !gsa && !isSalePrice && priceMin == priceMax:
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {`${formatUSPrice(priceMax)}`}
          </span>
        );

      // Default case when there is no sale price (Regular price for non-GSA and sale items)
      default:
        // Regular price should only be shown if it is not a GSA item and there is a sale price
        if (!gsa && isSalePrice) {
          return (
            <span
              className={`${classes.grey} ${isSalePrice ? classes['price-small'] : ''}`}
              style={{ display: 'block' }}
            >
              reg.{' '}
              {priceMax === priceMin
                ? formatUSPrice(priceMax)
                : `${formatUSPrice(priceMin)} - ${formatUSPrice(priceMax)}`}
            </span>
          );
        }
        return null;
    }
  };

  const getProductRecsPrice = (ele: FetchRecsProduct, isGsaUser: boolean) => {
    switch (true) {
      case ele?.isOnSale && !ele?.hasGSAPrice:
        return (
          <>
            <span style={{ display: 'block', color: 'red' }}>
              {ele?.salePriceRange}
            </span>
            <span
              className={`${classes.grey} ${ele.isOnSale ? classes['price-small'] : ''}`}
              style={{ display: 'block' }}
            >{`reg. ${ele?.listPriceRange}`}</span>
          </>
        );
      case isGsaUser && ele?.hasGSAPrice:
        return (
          <span style={{ display: 'block', color: '#008000' }}>
            {ele?.salePriceRange} GSA
          </span>
        );
      case !ele?.hasGSAPrice && !ele?.isOnSale:
        return (
          <span
            className={`${classes.grey} ${classes['price-small']}`}
            style={{ display: 'block' }}
          >
            {ele?.listPriceRange}
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {products.map((ele: Product | FetchRecsProduct | any, index: number) => {
        let {
          name = '',
          url = '',
          displayImage = '',
          imageURL = '',
          seoURL = '',
        } = ele;

        return (
          <div
            key={`${name}-${index}`}
            className={`${classes['product-suggestions-mobile']} ${customClass ? classes[customClass] : ''} ${ele['@class']}`}
          >
            <Link
              data-type_ahead_product_click={true}
              href={isRecentResults ? seoURL : url}
            >
              <div className="row">
                <div className={`col-4 ${classes['product-sugg-img']}`}>
                  <Image
                    src={
                      isRecentResults
                        ? imageURL
                        : `${scene7BaseURL}/is/image/OCProduction/${displayImage}`
                    }
                    className="item-img autofill position-static"
                    fill={true}
                    alt={name}
                    unoptimized={true}
                  />
                </div>
                <div className={`col-8 ${classes['product-sugg-desc']}`}>
                  <Link
                    className="productSuggestion"
                    data-type_ahead_product_click={true}
                    dangerouslySetInnerHTML={{ __html: name }}
                  ></Link>
                  <div className="tt-price">
                    {isRecentResults
                      ? getProductRecsPrice(ele, gsaUser)
                      : getPriceDisplay(ele, gsaUser)}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
