import { findComponent } from '@next-app/utils';
import {
  ContentSlot as ContentSlotInterface,
  Content,
} from '@next-app/interface/Common';

interface ContentSlotProps {
  contentItem: ContentSlotInterface;
}

export default function ContentSlot(props: ContentSlotProps) {
  const ComponentList = props?.contentItem?.contents.map(
    (item: Content, key: number) => {
      const Component = item && findComponent(item);
      if (!Component) return null;
      return <Component contentItem={item} key={key} />;
    },
  );

  return ComponentList;
}
