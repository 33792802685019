const setRecentSearch = (keyword: string) => {
  let recentSearches = getRecentSearch();
  recentSearches = recentSearches.filter(
    (termsSearched: string) => termsSearched != keyword,
  );
  if (recentSearches.length === 10) recentSearches.shift();
  recentSearches.push(keyword);
  localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  return;
};

const getRecentSearch = () => {
  try {
    const recentSearches: string | null =
      localStorage.getItem('recentSearches');
    return recentSearches ? JSON.parse(recentSearches) : [];
  } catch (error) {
    console.log(error);
  }
};

const removeRecentSearchTerm = (keyword: string) => {
  const recentSearches = getRecentSearch();
  recentSearches.splice(recentSearches.indexOf(keyword), 1);
  localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  return;
};

const setRecentlyViewedProduct = (productId: string, storeOnly: boolean) => {
  let recentlyViewed = getRecentlyViewedProduct();
  recentlyViewed = recentlyViewed.filter(
    (viewedProduct: { id: string }) => viewedProduct.id != productId,
  );
  if (recentlyViewed.length === 4) recentlyViewed.shift();
  recentlyViewed.push({ id: productId, storeOnly: storeOnly });
  localStorage.setItem('recentlyViewed', JSON.stringify(recentlyViewed));
  return;
};

const getRecentlyViewedProduct = (isGSAUser: boolean = false) => {
  try {
    const recentlyViewed: string | null =
      localStorage.getItem('recentlyViewed');
    let recentlyViewedProducts = recentlyViewed
      ? JSON.parse(recentlyViewed)
      : [];
    if (isGSAUser) {
      recentlyViewedProducts = recentlyViewedProducts?.filter(
        (product: { id: string; storeOnly: boolean }) =>
          product.storeOnly != true,
      );
    }
    return recentlyViewedProducts;
  } catch (error) {
    console.log(error);
  }
};

export {
  getRecentSearch,
  setRecentSearch,
  removeRecentSearchTerm,
  setRecentlyViewedProduct,
  getRecentlyViewedProduct,
};
