import { HeaderBlockContent } from '@next-app/interface/Header';
import {
  SectionHTMLTagUpdatedContent,
  SectionHTMLTagContent,
} from '@next-app/interface/PageContent';
import { findComponent, getUniqueKey } from '@next-app/utils';

interface SectionHTMLTagProps {
  contentItem: SectionHTMLTagUpdatedContent;
  isMobileNav: boolean;
  mainNavItem: string;
  mobileMainNavItem: string;
}

type DynamicComponentType = React.ComponentType<{
  contentItem: HeaderBlockContent;
  isMobileNav?: boolean;
  mainNavItem?: string;
  mobileMainNavItem?: string;
}>;

export default function SectionHTMLTag(props: SectionHTMLTagProps) {
  const { tag, tagID } = props.contentItem;

  const ComponentList = props.contentItem.content.map(
    (item: SectionHTMLTagContent, index: number) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      switch (tag) {
        case 'main': {
          return (
            <div key={getUniqueKey(index, `${item.name}-Main`)}>
              <DynamicComponent
                contentItem={item}
                key={getUniqueKey(index, `${item.name}-Main`)}
              />
            </div>
          );
        }
        case 'div': {
          return (
            <section key={getUniqueKey(index, `${item.name}-div`)}>
              <DynamicComponent
                mainNavItem={props.mainNavItem}
                contentItem={item}
                key={getUniqueKey(index, `${item.name}-div`)}
              />
            </section>
          );
        }
        case 'section': {
          return (
            <div key={getUniqueKey(index, `${item.name}-section`)}>
              <DynamicComponent
                contentItem={item}
                key={getUniqueKey(index, `${item.name}-section`)}
              />
            </div>
          );
        }
        case 'hr': {
          return <hr />;
        }
        case 'placeholder-div': {
          return (
            <div
              id={tagID}
              key={getUniqueKey(index, `${item.name}-placeholder-div`)}
            ></div>
          );
        }

        default: {
          return (
            <div key={getUniqueKey(index, `${item.name}-default`)}>
              <DynamicComponent
                contentItem={item}
                isMobileNav={props.isMobileNav}
                mobileMainNavItem={props.mobileMainNavItem}
                key={getUniqueKey(index, `${item.name}-default`)}
              />
            </div>
          );
        }
      }
    },
  );

  return props.contentItem.enabled ? ComponentList : null;
}
