'use client';

import { useEffect, useRef, useState, useCallback } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import classes from './facets.module.scss';
import Conditional from '@next-app/components/Conditional';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import { mapDynamicObject } from '@next-app/utils/dynamicComponent-mapping';
import {
  IMobFacetsProps,
  ComponentMap,
  PageContentFacets,
} from '@next-app/interface/PLP/index';
import {
  hasSelectedRefinement,
  removeDynamicIdsFromUrl,
  handleAllClear,
  getUrl,
  trimURL,
  removeDynamicIdsFromUrlSRP,
} from '@next-app/utils/get-guided-data';
import Button from '@next-app/components/elements/Button/Button';
import { addTrailingSlash, isBrowser } from '@next-app/utils/common-utils';
import { getFactsFilterCount } from './helper';
import GuidedNavigation from '@next-app/components/shared/PageContent/GuidedNavigation/GuidedNavigation';

const MobFacets = (props: IMobFacetsProps) => {
  const {
    guidedNavigation,
    filterCount,
    mainDimension,
    clearAllURL = '',
    assortmentTitle,
    metaTag,
    selectedNavigationBreadcrumbs = {},
    isSRP,
    searchCrumbs,
    totalNumRecs,
  } = props;
  const { count: mobFilterCount } = mainDimension || {};

  const router = useRouter();
  const pathname = usePathname();
  let currentUrl = pathname;

  useEffect(() => {
    if (isBrowser()) {
      const searchUrl = window?.location?.search || '';
      currentUrl = `${pathname}${searchUrl}`;
      if (!pathname.includes('/N/') && isSRP) {
        sessionStorage.setItem(
          'srpInitial',
          JSON.stringify({
            label: `"${searchCrumbs?.[0]?.terms}" results`,
            url: `${pathname}${searchUrl}`,
            path: pathname,
            search: searchUrl,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSRP]);

  const navSideRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLSpanElement>(null);
  const [filterActive, setFilterActive] = useState(false);
  const [count, setFilterCount] = useState(
    isSRP ? totalNumRecs : mobFilterCount,
  );
  const [updatedUrl, setUpdatedUrl] = useState(isSRP ? currentUrl : pathname);
  const [isFilter, setIsFilter] = useState('All');
  const [filterLabel, setFilterLabel] = useState('');
  const [selectedFilter, setSelectedFilter] = useState();

  const handleFilter = () => {
    setFilterActive((prev) => !prev);

    if (!filterActive) {
      document.body.classList.add('filter-open');
    } else {
      document.body.classList.remove('filter-open');
    }
  };

  const fetchData = async (selectedProductLink: string) => {
    const url = `${selectedProductLink}`;
    let navUrl = url;
    if (!navUrl.startsWith('/')) {
      navUrl = '/' + url;
    }
    const newUrl = getUrl(navUrl) || navUrl;
    try {
      const responseData = await makeFetchCall({
        endpoint: newUrl,
      });

      setSelectedFilter(responseData);

      const getCount = getFactsFilterCount(responseData);

      setFilterCount(getCount);
      return responseData;
    } catch (error) {
      console.error('Error fetching searching products:', error);
      return [];
    }
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      navSideRef.current &&
      !navSideRef.current.contains(event.target as Node) &&
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target as Node)
    ) {
      setFilterActive(false);
    }
  }, []);

  const getFilterLabel = (value: boolean) => {
    setFilterActive(value);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const componentMap: ComponentMap = {};
  selectedFilter &&
    mapDynamicObject(selectedFilter['@type'], selectedFilter, componentMap);

  let navigation = guidedNavigation;
  let navFilterCount = count;
  let newNavBreadcrumbs: { [key: string]: string } =
    selectedNavigationBreadcrumbs;

  let mobileNavTitle = assortmentTitle
    ? assortmentTitle
    : metaTag?.parentCategory || metaTag?.pageName || '';
  if ('PageContent-AssortmentListing' in componentMap) {
    const assortment = componentMap['PageContent-AssortmentListing'];
    navigation = assortment?.guidedNavigation;

    navFilterCount =
      (assortment?.assortmentResultsList &&
        assortment?.assortmentResultsList[0]?.totalNumRecs) ||
      count;
    newNavBreadcrumbs =
      assortment?.assortmentResultsList &&
      assortment?.assortmentResultsList[0]?.selectedNavigationBreadcrumbs;

    mobileNavTitle =
      assortment?.assortmentResultsList &&
      assortment?.assortmentResultsList[0]?.assortmentTitle;
  } else if ('PageContent-ProductListing' in componentMap) {
    const productList = componentMap['PageContent-ProductListing'];
    navigation = productList?.guidedNavigation;
    navFilterCount =
      (productList?.productListingInfo &&
        productList?.productListingInfo[0]?.mainDimension?.count) ||
      count;
    newNavBreadcrumbs =
      (productList?.resultList &&
        productList?.resultList[0]?.selectedNavigationBreadcrumbs) ||
      selectedNavigationBreadcrumbs;
  }

  navigation[0]?.facets?.map((el: PageContentFacets) => {
    if (el['@type'] === 'PageContent-CategoryNavRefinementMenu') {
      mobileNavTitle = el?.mainCategoryDisplayWithoutCount
        ? el?.mainCategoryDisplayWithoutCount
        : mobileNavTitle;
    }
    if (
      mobileNavTitle === '' &&
      el['@type'] === 'PageContent-NonCategoryNavRefinementMenu'
    ) {
      mobileNavTitle = el?.assortmentName ? el?.assortmentName : mobileNavTitle;
    }
  });
  mobileNavTitle =
    (isSRP ? `"${searchCrumbs?.[0]?.terms}" results` : mobileNavTitle) || '';
  const hasFilter = hasSelectedRefinement(navigation[0], isFilter) || false;
  const hasAllClear =
    (handleAllClear(navigation[0]) || false) && isFilter === 'All';

  const allClearUrl = newNavBreadcrumbs[mobileNavTitle]?.split('+')[0]
    ? newNavBreadcrumbs[mobileNavTitle]?.split('+')[0]
    : clearAllURL;

  const viewResultUrl = trimURL(updatedUrl);
  const filterNavCount =
    Object.keys(selectedNavigationBreadcrumbs).length > 3
      ? filterCount + 1
      : filterCount;

  const clearAllFilters = () => {
    let srpFilterData;

    if (isSRP && isBrowser()) {
      const srpFilter = sessionStorage.getItem('srpInitial') || '';
      srpFilterData = JSON.parse(srpFilter);
    }
    const newAllclearUrl =
      isSRP && srpFilterData?.url
        ? addTrailingSlash(srpFilterData.url)
        : addTrailingSlash(allClearUrl);

    if (pathname === newAllclearUrl || pathname === srpFilterData.path) {
      fetchData(newAllclearUrl);
      handleFilter();
    } else {
      router.push(newAllclearUrl);
    }
    document.body.classList.remove('filter-open');
  };

  const handleViewResult = () => {
    if (pathname === viewResultUrl || currentUrl == viewResultUrl) {
      fetchData(viewResultUrl);
      handleFilter();
    } else {
      router.push(viewResultUrl);
    }
    document.body.classList.remove('filter-open');
  };

  const handleClear = async () => {
    let newUpdatedUrl = updatedUrl;
    if (!updatedUrl.startsWith('/')) {
      newUpdatedUrl = `/${updatedUrl}`;
    }

    if (isSRP) {
      const newURL = removeDynamicIdsFromUrlSRP(
        newUpdatedUrl,
        navigation[0],
        isFilter,
      );
      setUpdatedUrl(newURL);
      fetchData(newURL);
    } else {
      const newURL = removeDynamicIdsFromUrl(
        newUpdatedUrl,
        navigation[0],
        isFilter,
      );
      setUpdatedUrl(newURL);
      fetchData(newURL);
    }
  };

  return (
    <div>
      <span
        ref={filterButtonRef}
        className={`${classes['filter-text']} ${filterActive ? classes['active'] : ''}`}
        onClick={handleFilter}
      >
        Filter {filterNavCount ? `(${filterNavCount})` : ''}
      </span>
      <div
        ref={navSideRef}
        className={`${classes['filter-guided-nav']} ${filterActive ? classes['active'] : ''}`}
      >
        <h2 className={classes['filter-title']}>
          <span
            className={`${isSRP ? classes['bold'] : ''}`}
            dangerouslySetInnerHTML={{
              __html: mobileNavTitle,
            }}
          />
          <hr className={classes['hr-divider']} />
        </h2>
        <div
          className={`${classes['scroll-container']} ${hasFilter || hasAllClear ? classes['active-clear'] : ''}`}
        >
          <Conditional if={navigation.length}>
            <GuidedNavigation
              contentItem={navigation[0]}
              getFilterLabel={getFilterLabel}
              key="GuidedNav"
              setFilterCount={setFilterCount}
              setUpdatedUrl={setUpdatedUrl}
              setIsFilter={setIsFilter}
              isFilter={isFilter}
              setFilterLabel={setFilterLabel}
              filterLabel={filterLabel}
              setSelectedFilter={setSelectedFilter}
              assortmentTitle={assortmentTitle}
            />
          </Conditional>
        </div>
        <div className={classes['mob-buttons']}>
          <Conditional if={hasFilter || hasAllClear}>
            <button
              className={`${classes['mob-button']} ${classes['clear']}`}
              onClick={() => {
                isFilter === 'All' ? clearAllFilters() : handleClear();
              }}
            >
              Clear {isFilter} Filters
            </button>
          </Conditional>
          <Button
            link={viewResultUrl}
            customClass={`${classes['mob-button']} ${classes['view']}`}
            onClick={() => {
              router.push(viewResultUrl);
              handleViewResult();
            }}
          >{`View ${navFilterCount} Results`}</Button>
        </div>
      </div>
    </div>
  );
};

export default MobFacets;
