import { findComponent } from '@next-app/utils';
import classes from './image-row.module.scss';
import Conditional from '@next-app/components/Conditional';
import Heading from '@next-app/components/elements/Heading/Heading';
import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';
import { isNotNullOrUndefined } from '@next-app/utils/common-utils';
interface ImageRowProps {
  children: React.JSX.Element;
  contentItem: {
    lazyload: boolean;
    hasFStyle: boolean;
    images: [];
    imageCellStyles: string;
    title: string;
    displayBottomTextAsTitle: boolean;
    isSingleStackOnMobile: boolean;
    isSingleStackOnTablet: boolean;
    getProductsData: boolean;
    textStyles: string;
    enabled: boolean;
    imageRowCssClass: string;
    isBottomTextVisibleOnMobile: boolean;
    name: string;
    ctaButtonAttributes: string;
    hasTwoAssetsInARow: boolean;
    fullWidth?: boolean;
  };
}

type DynamicComponentType = React.ComponentType<ImageRowProps>;

const ImageRow = (props: ImageRowProps) => {
  const {
    images,
    imageCellStyles,
    title,
    imageRowCssClass,
    displayBottomTextAsTitle,
    isSingleStackOnMobile,
    isSingleStackOnTablet,
    textStyles,
    isBottomTextVisibleOnMobile,
    ctaButtonAttributes,
    lazyload,
    hasTwoAssetsInARow,
    fullWidth,
  } = props.contentItem;

  const dynamicComponentProps = {
    imageCellStyles,
    displayBottomTextAsTitle,
    isSingleStackOnMobile,
    textStyles,
    isBottomTextVisibleOnMobile,
    title,
    imageRowCssClass,
    images,
    ctaButtonAttributes,
  };

  let desktopClassName = 'col col-lg';
  let tabletClassName = isSingleStackOnTablet ? 'singleStackTablet' : '';
  let mobileClassName = isSingleStackOnMobile
    ? 'col-12 col-md singleStackMobile'
    : '';

  const columnClassName = `${desktopClassName} ${tabletClassName} ${mobileClassName}`;

  const ImageComponentList = images.map((item, index: number) => {
    const {
      scene7Asset,
      scene7DesktopParams,
      scene7MobileAsset,
      scene7MobileParams,
      desktopImgUrl,
      mobileImgUrl,
      altText,
    } = item;

    let desktopImageURLVal = '';
    let mobileImageURLVal = '';
    if (
      scene7Asset &&
      scene7DesktopParams !== '' &&
      scene7MobileParams !== '' &&
      scene7MobileAsset !== ''
    ) {
      desktopImageURLVal = `/${scene7Asset}?${scene7DesktopParams}`;
      mobileImageURLVal = `/${scene7MobileAsset}?${scene7MobileParams}`;
    } else {
      desktopImageURLVal = desktopImgUrl;
      mobileImageURLVal = mobileImgUrl;
    }

    const DynamicComponent = findComponent(item) as DynamicComponentType;

    return (
      <>
        <Conditional
          if={
            hasTwoAssetsInARow && images.length > 3 && index < images.length - 2
          }
        >
          <div className={columnClassName} key={index}>
            <DynamicComponent
              contentItem={item}
              {...dynamicComponentProps}
              key={index}
            >
              <ResponsiveImage
                desktopImagePath={desktopImageURLVal}
                mobileImagePath={mobileImageURLVal}
                tabletImagePath={mobileImageURLVal}
                className="w-100 round-border h-auto"
                altText={altText}
                isFullUrl={true}
                width="500"
                height="500"
                lazyload={lazyload}
              />
            </DynamicComponent>
          </div>
        </Conditional>

        <Conditional if={!hasTwoAssetsInARow || images.length < 3}>
          <div className={columnClassName} key={index}>
            <DynamicComponent
              contentItem={item}
              {...dynamicComponentProps}
              key={index}
            >
              <ResponsiveImage
                desktopImagePath={desktopImageURLVal}
                mobileImagePath={mobileImageURLVal}
                tabletImagePath={mobileImageURLVal}
                className="w-100 round-border h-auto"
                altText={altText}
                isFullUrl={true}
                width="500"
                height="500"
                lazyload={lazyload}
              />
            </DynamicComponent>
          </div>
        </Conditional>
      </>
    );
  });

  const lastTwoImages = images.map((image, index) => {
    const {
      scene7Asset,
      scene7DesktopParams,
      scene7MobileAsset,
      scene7MobileParams,
      desktopImgUrl,
      mobileImgUrl,
      altText,
    } = image;

    let desktopImageURLVal = '';
    let mobileImageURLVal = '';
    if (
      scene7Asset &&
      scene7DesktopParams !== '' &&
      scene7MobileParams !== '' &&
      scene7MobileAsset !== ''
    ) {
      desktopImageURLVal = `/${scene7Asset}?${scene7DesktopParams}`;
      mobileImageURLVal = `/${scene7MobileAsset}?${scene7MobileParams}`;
    } else {
      desktopImageURLVal = desktopImgUrl;
      mobileImageURLVal = mobileImgUrl;
    }
    const DynamicComponent = findComponent(image) as DynamicComponentType;

    return (
      <Conditional
        if={hasTwoAssetsInARow && index >= images.length - 2}
        key={index}
      >
        <div
          className={
            index === images.length - 2 ? classes['image-row-banner-wrap'] : ''
          }
        >
          <DynamicComponent
            contentItem={image}
            {...dynamicComponentProps}
            key={index}
          >
            <ResponsiveImage
              desktopImagePath={desktopImageURLVal}
              mobileImagePath={mobileImageURLVal}
              tabletImagePath={mobileImageURLVal}
              className="w-100 round-border h-auto"
              altText={altText}
              isFullUrl={true}
              width="500"
              height="500"
              lazyload={lazyload}
            />
          </DynamicComponent>
        </div>
      </Conditional>
    );
  });

  return (
    <div className={fullWidth ? 'full-width-container' : 'page-container'}>
      <div className={`${classes['section-image-row']}`}>
        <div className={`${imageRowCssClass} row g-3 ${classes['imageRow']}`}>
          <Conditional if={isNotNullOrUndefined(title) && title?.length > 0}>
            <Heading tagName="2">{title}</Heading>
          </Conditional>
          {ImageComponentList}
          <Conditional if={hasTwoAssetsInARow}>
            <div
              className={`${columnClassName} ${classes['image-row-banner-wrap']}`}
            >
              {lastTwoImages}
            </div>
          </Conditional>
        </div>
      </div>
    </div>
  );
};

export default ImageRow;
