'use client';

import Conditional from '@next-app/components/Conditional';
import classes from './menu-tab.module.scss';
import getDevice from '@next-app/components/HOC/GetDevice';
import { useState } from 'react';
import {
  closeAllSelectDropdowns,
  resetHamburgerMenu,
} from '@next-app/utils/common-utils';

interface MenuTabHtmlProps {
  tabHeader: string;
  ComponentList: (React.JSX.Element | null)[];
  name: string;
  tabURL: string;
  isMobile: boolean;
  isWeb: boolean;
  isTab: boolean;
  mobileNavigation?: boolean;
}

const MenuTabHtml = ({
  tabHeader,
  ComponentList,
  name,
  tabURL,
  isMobile,
  isTab,
  isWeb,
  mobileNavigation,
}: MenuTabHtmlProps) => {
  const [hoverClass, setHoverClass] = useState('');
  const handleOpenMenuLevelSecond = () => {
    const menuItems = document.getElementById(name);

    const levelTwoDoc = menuItems && menuItems.querySelector('.level-2-menu');
    menuItems && menuItems.classList.add('active');
    if (levelTwoDoc === null) {
      const levelTwoTitle =
        menuItems && menuItems.querySelector('.level-2-title');
      levelTwoTitle && levelTwoTitle.classList.add('level-two-list');
      const levelThreeArrow =
        menuItems && menuItems.querySelector('.level-3-arrow');
      levelThreeArrow && levelThreeArrow.classList.add('hide');
      const levelThreeDoc =
        menuItems && menuItems.querySelector('.sub-menu-third-level');
      levelThreeDoc && levelThreeDoc.classList.add('active');
      const level3UlEle =
        levelThreeDoc && levelThreeDoc.querySelector('.level-3-ul');
      level3UlEle && level3UlEle.classList.add('level-three-list');
    }
  };

  const handleCloseMobileSecond = () => {
    const menuItemsClose = document.getElementById(name);
    menuItemsClose && menuItemsClose.classList.remove('active');
  };

  const adobeAttribute = JSON.stringify({
    vars: { events: 'event24', eVar: 'eVar1', list1: tabHeader },
  });

  return (
    <>
      <Conditional if={isWeb}>
        <li
          className={`${classes.dropdown} ${hoverClass} navigation-list`}
          onFocus={() => {
            setHoverClass('active');
            closeAllSelectDropdowns();
          }}
          onMouseOver={() => {
            setHoverClass('active');
            closeAllSelectDropdowns();
          }}
          onMouseLeave={() => {
            setHoverClass('');
          }}
          onBlur={() => setHoverClass('')}
        >
          <Conditional if={tabURL ? true : false}>
            <a
              automation-id={`${tabHeader}${mobileNavigation ? ' - Mobile' : ''}`}
              href={tabURL}
              {...{ s_objectid: tabHeader }}
              data-activity-map={adobeAttribute}
            >
              {tabHeader}
            </a>
          </Conditional>

          <Conditional if={!tabURL}>
            <span
              automation-id={`${tabHeader}${mobileNavigation ? ' - Mobile' : ''}`}
              {...{ s_objectid: tabHeader }}
              data-activity-map={adobeAttribute}
            >
              {tabHeader}
            </span>
          </Conditional>
          <div className={classes['nav-dropdown']}>
            <div className="container">
              <div className="row justify-content-center">{ComponentList}</div>
            </div>
          </div>
        </li>
      </Conditional>
      <Conditional if={isMobile || isTab}>
        <>
          <Conditional if={tabHeader === 'Sale'}>
            <li className={classes['Menu-link-level-1']}>
              <a href={tabURL}>
                <span>{tabHeader}</span>
              </a>
            </li>
          </Conditional>
          <Conditional if={tabHeader !== 'Sale'}>
            <li className={classes['Menu-link-level-1']}>
              <a className="open-menu" onClick={handleOpenMenuLevelSecond}>
                <span>{tabHeader}</span>
                <span className={classes['sprites-right-arrow-icon']}></span>
              </a>
              <div
                id={name}
                className={`sub-menu-level-2 ${classes['mp-level']}`}
                data-level="2"
              >
                <div
                  className={`level-2-title ${classes['mp-back-title']}`}
                  onClick={handleCloseMobileSecond}
                >
                  <span
                    className={`${classes['sprites-left-arrow-icon']}`}
                  ></span>
                  <span className={`${classes['title-text']}`}>
                    {tabHeader}
                  </span>
                  <span
                    onClick={resetHamburgerMenu}
                    className={`sub-menu-close ${classes['close-hamburger-menu']}`}
                  >
                    <span
                      className={`${classes['sprites-close-icon']}`}
                      role="presentation"
                    ></span>
                  </span>
                </div>
                <ul>{ComponentList}</ul>
              </div>
            </li>
          </Conditional>
        </>
      </Conditional>
    </>
  );
};

export default getDevice(MenuTabHtml);
