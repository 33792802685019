'use client';

import { useEffect, useState } from 'react';
import Conditional from '@next-app/components/Conditional';
import { ProductsList } from './ProductsList';
import classes from './search.module.scss';
import { debounce } from '@next-app/utils/debounce';
import {
  fetchTypeaheadSuggestions,
  fetchFlyoutProductSuggestions,
} from './helper';
import { TypeAheadResponse } from '@next-app/interface/Common';
import { useSelector } from 'react-redux';
import { dyEvent } from '@next-app/utils/dy-event';
import { useRouter } from 'next/navigation';
import { setRecentSearch } from '@next-app/utils/recent-searches';
import Link from '@next-app/components/elements/Link/Link';
import { selectDynSess } from '@next-app/lib/features/InitSelectors';
import { SearchProps, Suggestion } from './Search.interface';
import searchArrow from '/public/assets/images/searchArrow.svg';
import Button from '@next-app/components/elements/Button/Button';
import Image from 'next/image';

const Suggestions: React.FC<SearchProps> = ({
  inputVal,
  keyPressedEvent,
  setInputVal,
  enhancedTypeAheadEnabled,
}) => {
  const DEBOUNCE_TIME = 300;
  const router = useRouter();
  const dynSessConfNumber = useSelector(selectDynSess);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [data, setData] = useState<TypeAheadResponse>({
    '@class': '',
    products: [],
    suggestions: [],
  });

  const { products = [], suggestions = [] } = data || {};

  const classValue = !Array.isArray(data) ? (data ? data['@class'] : '') : '';

  /**
   * handleProductSuggestionChange
   * @param linkVal - value of link hovered
   */
  const handleProductSuggestionChange = (linkVal: string) => {
    if (linkVal) {
      debounce(
        () =>
          fetchFlyoutProductSuggestions(
            linkVal,
            data,
            setData,
            dynSessConfNumber,
          ),
        DEBOUNCE_TIME,
      );
    }
  };

  /**
   * handleHighlightedIndex
   * @param arrowDirection
   */
  const handleHighlightedIndex = (arrowDirection: 'up' | 'down') => {
    const suggestionsLength = suggestions.length;
    switch (arrowDirection) {
      case 'up': {
        setHighlightedIndex((prevIndex: number) => {
          const val =
            prevIndex === -1
              ? suggestionsLength - 1
              : (prevIndex - 1 + suggestionsLength) % suggestionsLength;
          if (isNaN(val)) return -1;
          return val;
        });
        break;
      }

      case 'down': {
        setHighlightedIndex((prevIndex: number) => {
          const val = (prevIndex + 1) % suggestionsLength;
          if (isNaN(val)) return -1;
          return val;
        });
        break;
      }

      default: {
        // unsupported key pressed
        break;
      }
    }
  };

  /**
   * handleKeyDown
   * @param e
   */
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      handleHighlightedIndex('down');
    } else if (e.key === 'ArrowUp') {
      handleHighlightedIndex('up');
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault();
      const selectedSuggestion = suggestions[highlightedIndex];
      if (selectedSuggestion) {
        const properties = {
          dyType: 'keyword-search-v1',
          keywords: selectedSuggestion.search,
        };
        dyEvent({ properties: properties, eventName: 'Keyword Search' });
        setRecentSearch(selectedSuggestion.search);
        router.push(selectedSuggestion.url);
      }
    }
  };

  /**
   * handleSuggestionClick
   * @param e
   */
  const handleSuggestionClick = (e: Event, { search, url }: Suggestion) => {
    e.preventDefault();
    const properties = {
      dyType: 'keyword-search-v1',
      keywords: decodeURIComponent(search),
    };
    dyEvent({
      properties: properties,
      eventName: 'Keyword Search',
    });
    setRecentSearch(search);
    router.push(url);
  };

  // fetch new typeahead suggestions if focus is on search input and input value changes
  useEffect(() => {
    if (inputVal !== '' && highlightedIndex === -1) {
      debounce(() => {
        fetchTypeaheadSuggestions(inputVal, setData);
      }, DEBOUNCE_TIME);
    }
  }, [inputVal]);

  // invoke handleKeyDown if keypressed event occurs
  useEffect(() => {
    if (keyPressedEvent) {
      handleKeyDown(keyPressedEvent);
    }
  }, [keyPressedEvent]);

  //fetchFlyoutProductSuggestions if highlighted index changes
  useEffect(() => {
    if (highlightedIndex !== -1) {
      setInputVal(suggestions[highlightedIndex]?.search);
      handleProductSuggestionChange(suggestions[highlightedIndex]?.search);
    }
  }, [highlightedIndex]);

  return (
    <Conditional
      if={inputVal.length > 0 && suggestions.length > 0 && products.length > 0}
    >
      <div className={`${classes.searchWrapper}`}>
        <div className={`${classValue} row m-0`}>
          <div className={`col-12 col-md-6 ${classes.suggestionBorder}`}>
            <h5 className="fw-bold d-none d-md-block">Search Suggestions:</h5>
            <Conditional if={suggestions && suggestions.length > 0}>
              <>
                {suggestions.map((suggestion: Suggestion, index: number) => {
                  const { search, url } = suggestion;
                  const inputLowerCase = inputVal.toLowerCase();
                  const isInputIncluded = search.includes(inputLowerCase);
                  const parsedSearch = search.split(inputLowerCase);
                  return (
                    <div
                      key={`${search}-${index}`}
                      className={`${suggestion['@class']} ${index === highlightedIndex ? classes['tt-cursor'] : ''}  ${enhancedTypeAheadEnabled ? classes['suggestionItem'] : ''}`}
                    >
                      <Link
                        data-internal_search_term_typeahead={true}
                        onClick={(e) => handleSuggestionClick(e, suggestion)}
                        href={url}
                        onMouseOver={() =>
                          handleProductSuggestionChange(search)
                        }
                        onKeyUp={() => {
                          handleProductSuggestionChange(search);
                        }}
                      >
                        <div className="row">
                          <div className="col-xs-8">
                            <div className={`${classes['item-title']}`}>
                              <strong>{parsedSearch[0]}</strong>
                              {isInputIncluded && inputLowerCase}
                              <strong>{parsedSearch[1]}</strong>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Conditional if={enhancedTypeAheadEnabled === true}>
                        <Button
                          customClass={`${classes['searchArrow']}`}
                          onClick={() => {
                            setInputVal(suggestion?.search);
                          }}
                          variant="transparent"
                        >
                          <Image
                            src={searchArrow}
                            alt="Close Arrow"
                            unoptimized={true}
                          />
                        </Button>
                      </Conditional>
                    </div>
                  );
                })}
              </>
            </Conditional>
          </div>
          <div
            className={`col-12 col-md-6 d-md-block ${classes['product-suggestions']}  ${enhancedTypeAheadEnabled ? classes['product-suggestions-wrp'] : 'd-none'}`}
          >
            <h5 className="fw-bold">Product Suggestions:</h5>
            <Conditional if={products && products.length > 0}>
              <ProductsList products={products} />
            </Conditional>
          </div>
        </div>
      </div>
    </Conditional>
  );
};

export default Suggestions;
