import { IPLPParams } from '@next-app/interface/PLP/ProductListPage';
import { makeFetchCall } from './fetch-data';
import { generatePLPPath } from './generatePLPath';
import { generatePDPPath } from './generatePDPPath';

interface ProductAPIReqOptions {
  options?: { method: string };
  body?: Record<string, string>;
  endpoint: string;
  cache: string;
  headers: Record<string, string>;
}

const getData = async (
  params: IPLPParams,
  isSale: boolean,
  isGSA: boolean,
  requestHeaders: { [key: string]: string } = {},
  isPDPPage = false,
  Endeca_user_segments?: string,
  Endeca_date?: string,
) => {
  let res;
  // adding isGSA request header for all PLP calls
  if (Endeca_user_segments) {
    requestHeaders['Endeca_user_segments'] = Endeca_user_segments;
  }
  if (Endeca_date) {
    requestHeaders['Endeca_date'] = Endeca_date;
  }

  if (isGSA) {
    requestHeaders['isGSA'] = 'true';
  }
  if (isPDPPage) {
    let pdpContentReqOptions: ProductAPIReqOptions = {
      endpoint: await generatePDPPath(params?.productFilter || [], isGSA),
      cache: 'force-cache',
      headers: requestHeaders,
    };

    res = await makeFetchCall(pdpContentReqOptions);
  } else {
    let reqOptions: ProductAPIReqOptions = {
      endpoint: await generatePLPPath(
        params,
        isSale,
        isGSA,
        '',
        isPDPPage,
        Endeca_user_segments,
        Endeca_date,
      ),
      cache: 'force-cache',
      headers: requestHeaders,
    };
    res = await makeFetchCall(reqOptions);
  }

  return res;
};

export default getData;
