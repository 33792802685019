'use client';

import { useDispatch, useSelector } from 'react-redux';
import { setSeo } from '@next-app/lib/store';
import { useEffect } from 'react';

const SeoSetup = ({ seoItem }: any) => {
  const initial = useSelector((state: any) => state.init);
  const { seoData: allSeoData = [] } = initial;

  const dispatch = useDispatch();

  useEffect(() => {
    if (initial && initial.seoData) {
      const newData =
        (allSeoData &&
          Array.isArray(allSeoData) &&
          allSeoData.length &&
          allSeoData?.find((el) => el?.url === seoItem?.[0]?.url)) ||
        {};
      const newSeo =
        newData && Object.keys(newData)?.length
          ? allSeoData
          : [...allSeoData, ...seoItem];

      dispatch(
        setSeo({
          seoData: newSeo,
        }),
      );
    }
  }, []);

  return null;
};

export default SeoSetup;
