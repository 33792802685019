import urlMapping from '@next-app/config/urlMapping';
import {
  LoginRequest,
  LoginResponse,
  Profile,
  Token,
} from '@shared/interfaces/Login';
import { clearLocalStorage } from './local-storage-utils';
import { makeFetchCall } from './fetch-data';
import configuration from '@next-app/config/configurations';

export const logoutUser = async (
  profileId: string,
  sessionConfirmationNumber: string,
) => {
  const body = {
    profileId: profileId,
    _dynSessConf: sessionConfirmationNumber,
  };
  await makeFetchCall({
    options: {
      method: 'POST',
    },
    endpoint: urlMapping.logout,
    body,
  });
  clearLocalStorage();
  window.location.href = configuration?.baseUrl || '';
};

export const loginUser = async (
  email: string,
  password: string,
  staySignedIn: boolean,
  sessionConfirmationNumber: string,
  authIntegrationInfoUrl: string,
) => {
  const body: LoginRequest = {
    login: email,
    password: password,
    userType: 'b2c-user',
    _dynSessConf: sessionConfirmationNumber,
    staySignedIn,
    sessionRestored: false,
    currentURL: location.href,
  };

  const loginResponse: LoginResponse = await makeFetchCall({
    options: {
      method: 'POST',
    },
    endpoint: urlMapping.login,
    body,
  });

  if (staySignedIn && loginResponse?.sessionInfo) {
    const { token, profile } = loginResponse.sessionInfo;
    storeAuthInfo(token, profile, authIntegrationInfoUrl);
  }
  return loginResponse;
};

export const storeAuthInfo = (
  token: Token | undefined,
  profile?: Profile,
  authIntegrationInfoUrl?: string,
): void => {
  if (token) {
    const { access_token, refresh_token, expires_in } = token;
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('refreshToken', refresh_token);
    localStorage.setItem(
      'expireDate',
      String(new Date().getTime() + expires_in * 1000),
    );
  }
  if (profile) {
    localStorage.setItem('profileId', profile.id);
  }

  if (authIntegrationInfoUrl) {
    localStorage.setItem('authIntegrationInfoUrl', authIntegrationInfoUrl);
  }
};

/**
 * Remove stored values from localstorage related to stay signed in user
 */
export const clearAuthInfo = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('expireDate');
  localStorage.removeItem('profileId');
  localStorage.removeItem('authIntegrationInfoUrl');
};

export const forgotUserResetCode = async (
  email = '',
  sessionConfirmationNumber = '',
) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.forgotResetCodUrl}`,
      options: {
        method: 'POST',
      },
      body: {
        email: email,
        _dynSessConf: sessionConfirmationNumber,
      },
    });

    return response;
  } catch (error) {
    console.error('Error Forgot Reset:', error);
  }
};

export const forgotUserResetCodeValidate = async (
  email = '',
  code = '',
  sessionConfirmationNumber: string | number,
) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.forgotValidatePwdResetCode}`,
      options: {
        method: 'POST',
      },
      body: {
        email: email,
        code: code,
        _dynSessConf: sessionConfirmationNumber,
      },
    });

    return response;
  } catch (error) {
    console.error('Error Validate code:', error);
  }
};

export const resetPasswordB2C = async (
  email = '',
  password = '',
  confirmPassword = '',
  pwdResetCode = '',
  pin = '',
  sessionConfirmationNumber: string | number,
) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.forgotResetPasswordB2C}`,
      options: {
        method: 'POST',
      },
      body: {
        login: email,
        password: password,
        confirmPassword: confirmPassword,
        pwdResetCode: pwdResetCode,
        pin: pin,
        _dynSessConf: sessionConfirmationNumber,
      },
    });
    return response;
  } catch (error) {
    console.error('Error Reset password:', error);
  }
};

export const createAccountUser = async ({
  email,
  password,
  firstName,
  lastName,
  emailOptIn,
  staySignedIn,
  userShopFor,
  sessionConfirmationNumber,
  phoneNumber,
  postalCode,
  termsAccepted,
  confirmPassword,
  authIntegrationInfoUrl,
}: {
  email: string;
  password: any;
  confirmPassword: any;
  firstName: string;
  lastName: string;
  emailOptIn: boolean;
  staySignedIn: boolean;
  userShopFor: string;
  phoneNumber: string;
  postalCode: string;
  termsAccepted: boolean;
  sessionConfirmationNumber: string;
  authIntegrationInfoUrl: string;
}) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.createAccountUserUrl}`,
      options: {
        method: 'POST',
      },
      body: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        phoneNumber: phoneNumber,
        postalCode: postalCode,
        confirmPassword: confirmPassword,
        emailOptIn: emailOptIn,
        staySignedIn: staySignedIn,
        termsAccepted: termsAccepted,
        userShopFor: userShopFor,
        _dynSessConf: sessionConfirmationNumber,
      },
    });
    if (staySignedIn && response?.sessionInfo) {
      const { token, profile } = response.sessionInfo;
      storeAuthInfo(token, profile, authIntegrationInfoUrl);
    }
    return response;
  } catch (error) {
    console.error('Error Create Account', error);
  }
};

export const GSAUser = async ({
  email,
  password,
  firstName,
  lastName,
  emailOptIn,
  staySignedIn,
  userShopFor,
  sessionRestored,
  sessionConfirmationNumber,
  authIntegrationInfoUrl,
}: {
  email: string;
  password: any;
  firstName: string;
  lastName: string;
  emailOptIn: boolean;
  userShopFor: string;
  staySignedIn: boolean;
  sessionRestored: boolean;
  sessionConfirmationNumber: string;
  authIntegrationInfoUrl?: string;
}) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.GSAUserUrl}`,
      options: {
        method: 'POST',
      },
      body: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        emailOptIn: emailOptIn,
        userShopFor: userShopFor,
        staySignedIn: staySignedIn,
        sessionRestored: sessionRestored,
        _dynSessConf: sessionConfirmationNumber,
      },
    });
    if (staySignedIn && response?.sessionInfo) {
      const { token, profile } = response.sessionInfo;
      storeAuthInfo(token, profile, authIntegrationInfoUrl);
    }
    return response;
  } catch (error) {
    console.error('Error GSA User:', error);
  }
};

export const GSARegistration = async ({
  email,
  sessionConfirmationNumber,
}: {
  email: string;
  sessionConfirmationNumber: string;
}) => {
  try {
    const response = await makeFetchCall({
      endpoint: `${urlMapping.GSARegistrationContinueUrl}`,
      options: {
        method: 'POST',
      },
      body: {
        email: email,
        _dynSessConf: sessionConfirmationNumber,
      },
    });

    return response;
  } catch (error) {
    console.error('Error GSA Register Continue:', error);
  }
};
