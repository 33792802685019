'use client';

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './add-to-cart.module.scss';
import Button from '@next-app/components/elements/Button/Button';
import {
  addToCart,
  checkGiftCardEligibility,
  fetchInventoryDetails,
  isPersonalized,
} from '@next-app/utils/PDPHelper';
import { PDPChildSKUInfo } from '@next-app/interface/PDP/PDPProductInfo';
import urlMapping from '@next-app/config/urlMapping';
import {
  AddEGiftCardToCart,
  AddNonGiftItemToCart,
  AddPhysicalGiftCardToCart,
  AddToCartResponse,
} from '@next-app/interface/PDP/PDPAddToCart';
import { updateCartCount } from '@next-app/lib/store';
import {
  isNotEmptyObject,
  getCartData,
  getPromoStatus,
  toKebabCase,
  isEmptyObject,
} from '@next-app/components/shared/ProductDetails/helper';
import {
  selectIsInStorePickUp,
  selectInventoryInfo,
  getDeliveryMethod,
  selectProductState,
  selectSelectedSku,
  selectChildSkus,
} from '@next-app/lib/features/PDP/PDPSelectors';
import {
  showDynamicModal,
  TrackConversion,
} from '@next-app/utils/common-utils';
import { dyEvent } from '@next-app/utils/dy-event';
import {
  updateSelectedItemQty,
  updateShowDropdownError,
  updateShowQtyError,
  updateStoreError,
  updateStoreOnlyStoreSelection,
} from '@next-app/lib/features/PDP/ProductSlice';
import { selectDropdownValues } from '@next-app/lib/features/PDP/PDPSelectors';
import { DynamicDTMEvent } from '@next-app/utils/dtm-event';
import { PDPState } from '@next-app/lib/features/PDP/PDPSlice.interface';
import {
  selectFeatureToggles,
  selectIsSessionInfoAvailable,
  selectIsStoreSelected,
} from '@next-app/lib/features/InitSelectors';
import Conditional from '@next-app/components/Conditional';
import SelectStoreModal from '../StoreSelectorModal/SelectStoreModal';
import Link from '@next-app/components/elements/Link/Link';
import dynamic from 'next/dynamic';

const AddedToCartModal = dynamic(
  () => import('@next-app/components/PDP/AddedToCartModal/AddedToCartModal'),
  { ssr: false },
);

const RegistryAddressModal = dynamic(
  () =>
    import('@next-app/components/PDP/AddedToCartModal/RegistryAddressModal'),
  { ssr: false },
);

interface AddToCartProps {
  selectedSku: PDPChildSKUInfo | null;
  setErrors?: Function;
  isFixed?: boolean;
  buttonId?: string;
  productId: string;
  carouselsName?: string;
  carouselId?: string;
  mboxid?: string;
  isAccessoriesCart?: boolean;
  // eslint-disable-next-line no-unused-vars
  setFormError?: (error: boolean) => void; // Make optional
}

const AddToCart = ({
  selectedSku,
  setErrors,
  isFixed,
  buttonId = '',
  productId = '',
  carouselsName = '',
  carouselId = '',
  mboxid = '',
  isAccessoriesCart = false,
  setFormError,
}: AddToCartProps) => {
  const [showModal, setShowModal] = useState(false);
  const {
    id = '',
    repositoryId = '',
    skuType = '',
    design = '',
    storeOnly: isItemStoreOnly = false,
  } = selectedSku || {};

  const [showRegistryModal, setShowRegistryModal] = useState(false);
  const [showSelectStoreModal, setShowSelectStoreModal] = useState(false);

  const isSessionInfoAvailable = useSelector(selectIsSessionInfoAvailable);

  const stateData = useSelector((state: any) => state?.init);
  const PDPDetails = useSelector((state: PDPState) =>
    selectProductState(state, productId),
  );
  const isInStorePickUp = useSelector((state: PDPState) =>
    selectIsInStorePickUp(state, productId),
  );
  const dropdownValues = useSelector((state: PDPState) =>
    selectDropdownValues(state, productId),
  );
  const deliveryMethod = useSelector((state: PDPState) =>
    getDeliveryMethod(state, productId),
  );
  const childSkus = useSelector((state: PDPState) =>
    selectChildSkus(state, productId),
  );

  let updatedSku = useSelector((state: PDPState) =>
    selectSelectedSku(state, productId),
  );

  if (isEmptyObject(updatedSku) && childSkus?.length === 1) {
    updatedSku = childSkus[0];
  }
  const isStoreSelected = useSelector(selectIsStoreSelected);
  const featureToggles = useSelector(selectFeatureToggles);
  const bopisEnabled = featureToggles?.bopisEnabled;

  const {
    init: { initResponse: { internalAPI = '' } = {} } = {},
    sessionInfo,
  } = stateData || {};

  const {
    selectedGiftCardDetails: {
      amount = '',
      customAmount = '',
      recepientEmail = '',
      recepientName = '',
      message = '',
      userName = '',
    } = {},
    personalization: { message: customMessage = '' } = {},
  } = PDPDetails || {};

  const {
    dynSess: { dynSessConfNumber = '' } = {},
    profile: { email = '', selectedStore: { id: locationId = '' } = {} } = {},
    cartInfo: { cartId = '' } = {},
  } = sessionInfo?.sessionInfo || {};
  const { profile } = sessionInfo?.sessionInfo || {};

  const firstName = profile?.firstName || '';

  const dispatch = useDispatch();
  const [addToCartData, setAddToCartData] = useState<AddToCartResponse>();
  const [buttonText, setButtonText] = useState('Add to Cart');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isFaddingOut, setIsFaddingOut] = useState(false);
  const [isAllDropdownSelected, setIsAllDropdownSelected] = useState(false);
  const [isErrorMsgShow, setIsErrorMsgShow] = useState(false);

  const [registryGC, setRegistryGC] = useState<{
    encryptedRegistryId: string;
    giftRecipientName: string;
  } | null>(null);
  const [registryId, setRegistryId] = useState<string>('');

  const isGiftCard = checkGiftCardEligibility(selectedSku);
  const inventoryInfo = useSelector((state: PDPState) =>
    selectInventoryInfo(state, productId),
  );

  useEffect(() => {
    const registryGCSession = sessionStorage.getItem('registryGC') || '';
    const parsedRegistryGC = registryGCSession && JSON.parse(registryGCSession);
    setRegistryGC(parsedRegistryGC);
    if (registryGC?.encryptedRegistryId) {
      setRegistryId(registryGC?.encryptedRegistryId);
    }
  }, [registryGC]);

  useEffect(() => {
    const validateDropdownValues = () => {
      const allValuesSelected = Object.values(dropdownValues || {}).every(
        (val) => val !== '',
      );
      const hasEmptyValue = !allValuesSelected;

      setIsErrorMsgShow(hasEmptyValue);
      setIsAllDropdownSelected(allValuesSelected);
    };

    validateDropdownValues();
  }, [dropdownValues]);

  const getInventory = useCallback(
    async (storeId: string) => {
      return await fetchInventoryDetails(
        storeId,
        dynSessConfNumber,
        internalAPI,
        selectedSku
          ? Array.isArray(selectedSku)
            ? selectedSku
            : [selectedSku]
          : (Array.isArray(childSkus) ? childSkus : childSkus) || [],
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dynSessConfNumber, selectedSku],
  );

  const isDiscontinued = updatedSku?.status === 'DISCONTINUED';
  const storeOnly = updatedSku?.storeOnly;
  const isSoldOut = updatedSku?.status === 'SOLD_OUT';
  const isSelectStoreError =
    !locationId && (selectedSku?.storeOnly || isSoldOut);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!bopisEnabled && storeOnly) {
      setIsButtonDisabled(true);
      setIsFaddingOut(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bopisEnabled]);

  useEffect(() => {
    if (locationId) {
      dispatch(
        updateStoreError({
          productId,
          showStoreError: '',
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const handleAddToCart = async () => {
    const selectedSkuId = PDPDetails?.selectedSku?.id || '';
    const selectedItemQty =
      PDPDetails?.selectedItemQty?.[productId || id || selectedSkuId] || 1;

    if (
      (!isErrorMsgShow || carouselsName) &&
      selectedItemQty !== '0' &&
      selectedItemQty !== '15+' &&
      !isSelectStoreError
    ) {
      setIsButtonDisabled(true);
      setIsFaddingOut(true);

      const apiEndpoint = isGiftCard
        ? urlMapping.giftCard
        : urlMapping.productCart;
      const userAmount =
        amount === 'Custom Amount' && customAmount ? customAmount : amount;
      const selectedSkuRepositoryId =
        PDPDetails?.selectedSku?.repositoryId || '';

      let req!:
        | AddEGiftCardToCart
        | AddNonGiftItemToCart
        | AddPhysicalGiftCardToCart;
      if (isGiftCard && skuType === 'giftcard') {
        req = {
          productId: productId || id || selectedSkuId,
          catalogRefId: repositoryId || selectedSkuRepositoryId,
          quantity: selectedItemQty,
          personalized: isPersonalized(message),
          amount: parseFloat(userAmount) || userAmount,
          recipientName: recepientName,
          senderName: userName || firstName,
          design: design,
          commerceItemType: skuType,
        };
      } else if (isGiftCard && skuType === 'eGiftcard') {
        req = {
          catalogRefId: repositoryId || selectedSkuRepositoryId,
          personalized: isPersonalized(message),
          productId: productId || id || selectedSkuId,
          quantity: selectedItemQty,
          amount: parseFloat(userAmount) || userAmount,
          commerceItemType: skuType,
          design,
          personalization: message,
          recipientEmail: recepientEmail,
          recipientName: recepientName,
          senderName: userName || firstName,
        };
      } else if (!isGiftCard) {
        if (storeOnly && !isStoreSelected) {
          dispatch(updateStoreOnlyStoreSelection({ productId, status: true }));
          setIsButtonDisabled(false);
          setIsFaddingOut(false);
          return;
        } else {
          req = {
            catalogRefId: selectedSkuRepositoryId || repositoryId || id,
            personalized: isPersonalized(customMessage),
            productId: productId || id || selectedSkuId,
            quantity: selectedItemQty,
            ...(customMessage ? { customMessage } : {}),
            ...(locationId &&
              (isInStorePickUp || isItemStoreOnly) && {
                locationId,
              }),
          };
        }
      }
      const addToCartResponse: AddToCartResponse = await addToCart(
        apiEndpoint,
        req,
        dynSessConfNumber,
        dispatch,
        isGiftCard && skuType === 'eGiftcard' && registryId,
      );
      dispatch(
        updateCartCount(addToCartResponse?.order?.totalCommerceItemCount || 0),
      );
      setAddToCartData(addToCartResponse);

      if (isNotEmptyObject(addToCartResponse?.order)) {
        const dyData = {
          eventName: 'Add to Cart',
          properties: {
            dyType: 'add-to-cart-v1',
            orderId: cartId ? cartId : profile.id,
            value:
              addToCartResponse?.order?.commerceItems?.[
                addToCartResponse?.order?.commerceItems?.length - 1
              ]?.priceInfo?.amount,
            productId: repositoryId || selectedSkuRepositoryId || id,
            quantity: selectedItemQty,
            cart: addToCartResponse?.order?.commerceItems?.map((cartItem) => {
              return {
                productId: cartItem?.productId,
                quantity: cartItem?.quantity,
                itemPrice: cartItem?.priceInfo?.listPrice,
              };
            }),
            location: locationId,
          },
        };
        const responseCommerceItem =
          addToCartResponse?.commerceItems?.[
            addToCartResponse?.commerceItems?.length - 1
          ]?.commerceItem;
        const type = isAccessoriesCart
          ? 'Accessory Item'
          : carouselsName || carouselId
            ? 'carousel'
            : 'pdp';
        const addToCartDTMdata = {
          event: 'addToCart',
          detail: {
            type: type,
            findingMethod: mboxid
              ? 'carousel'
              : carouselId
                ? toKebabCase(carouselId)
                : '',
            ...(mboxid && { algonomyPlacementId: mboxid }),
            ...(carouselsName && {
              carouselName: carouselsName,
              carouselId: carouselId,
            }),

            promoStatus: getPromoStatus(responseCommerceItem),
            orderId: cartId ? cartId : profile.id,
            deliveryMethod:
              deliveryMethod === 'store' && !carouselsName ? 'ropis' : 'ship',
            productId: productId || id || selectedSkuId,
            item: repositoryId,
            skuId: repositoryId,
            qty: selectedItemQty,
            encryptedOrderId: addToCartResponse?.encryptedOrderId,
            storeOnly: responseCommerceItem?.storeOnly,
            email: email,
            price: `$${(responseCommerceItem?.priceInfo?.amount || 1) / (responseCommerceItem?.quantity || 1)}`,
            name: 'addToCart',
            cart: {
              items:
                (addToCartResponse?.order?.commerceItems &&
                  getCartData(addToCartResponse.order.commerceItems)) ||
                [],
            },
            items: [
              {
                productId: productId || id || selectedSkuId,
                item: repositoryId,
                skuId: repositoryId,
                qty: selectedItemQty,
                price: `$${(responseCommerceItem?.priceInfo?.amount || 1) / (responseCommerceItem?.quantity || 1)}`,
                storeOnly: responseCommerceItem?.storeOnly,
                productName: responseCommerceItem?.gaEventData?.productName,
                primaryCategory: responseCommerceItem?.gaEventData?.category,
                primarySubCategory:
                  responseCommerceItem?.gaEventData?.subcategory,
                ageRange: responseCommerceItem?.gaEventData?.ageRange,
                gradeRange: responseCommerceItem?.gaEventData?.gradeRange,
                productUse:
                  responseCommerceItem?.gaEventData?.productUse?.length === 2
                    ? 'both'
                    : responseCommerceItem?.gaEventData?.productUse?.[0],
                deliveryMethod:
                  deliveryMethod === 'store' && !carouselsName
                    ? 'ropis'
                    : 'ship',
              },
            ],
          },
        };
        DynamicDTMEvent(addToCartDTMdata);
        dyEvent(dyData);
        setShowModal(true);
        dispatch(updateSelectedItemQty({ productId, selectedItemQty: 1 }));
        TrackConversion({
          event: 'addToCart',
          pageName: document.title,
          skuId: repositoryId || selectedSkuRepositoryId || id,
          orderId: cartId ? cartId : profile.id,
          trackConversionBaseUrl: internalAPI,
          dynSessConfNumber: dynSessConfNumber,
          dispatch,
          profileId: profile.id,
          cartId: cartId ? cartId : profile.id,
          productId: productId,
        });
        showDynamicModal();
        setButtonText('Added To Cart');
        const timerid1 = setTimeout(() => {
          setIsFaddingOut(false);
          clearTimeout(timerid1);
        }, 2000);
        const timerid2 = setTimeout(() => {
          setButtonText('Add to Cart');
          setIsButtonDisabled(false);
          clearTimeout(timerid2);
        }, 5000);
        // setFormError(false);
        if (setFormError) {
          setFormError(false);
        }
      } else {
        // error
        const formExceptions = addToCartResponse;
        // setFormError(true);
        if (setFormError) {
          setFormError(true);
        }
        setErrors && setErrors(formExceptions.formExceptions);
        setIsButtonDisabled(false);
        setIsFaddingOut(false);
      }
    } else {
      isAllDropdownSelected &&
      (selectedItemQty === '0' || selectedItemQty === '15+')
        ? dispatch(
            updateShowQtyError({
              productId,
              showQtyError: 'Please enter a quantity.',
            }),
          )
        : isSelectStoreError
          ? dispatch(updateStoreOnlyStoreSelection({ productId, status: true }))
          : dispatch(
              updateShowDropdownError({
                productId: productId,
                showDropdownError: isErrorMsgShow,
              }),
            );
    }
  };

  if (isDiscontinued && isFixed) {
    return;
  }

  if (
    isSoldOut &&
    isStoreSelected &&
    (inventoryInfo?.stockLevel === 'LimitedStock' ||
      inventoryInfo?.stockLevel === 'Unavailable')
  ) {
    return;
  }
  if (
    !isSoldOut &&
    isStoreSelected &&
    storeOnly &&
    (inventoryInfo?.stockLevel === 'LimitedStock' ||
      inventoryInfo?.stockLevel === 'Unavailable')
  ) {
    return;
  }

  if (
    !isSoldOut &&
    inventoryInfo?.ineligibleAtAllLocations &&
    storeOnly &&
    isStoreSelected &&
    inventoryInfo?.stockLevel === 'Available'
  ) {
    return;
  }

  const openRegistryModal = () => {
    setShowModal(false);
    setShowRegistryModal(true);
  };

  return (
    <>
      <div
        className={`${classes['add-to-cart-wrap']} ${isFixed ? classes['fixed'] : ''}`}
      >
        <Conditional if={!isItemStoreOnly || !carouselId || locationId}>
          <Button
            data-testid="add-to-cart-button"
            onClick={handleAddToCart}
            customClass={`${classes['add-to-cart-button']} ${isFaddingOut && classes['fade-out']}`}
            disabled={isButtonDisabled}
            id={buttonId}
          >
            {buttonText === 'Added To Cart' && (
              <span className={`ls-icon ${classes['icn-check-hvy']} `}></span>
            )}
            {buttonText}
          </Button>
        </Conditional>
      </div>
      <Conditional if={!showSelectStoreModal && showModal}>
        <AddedToCartModal
          productId={productId}
          addToCartData={addToCartData}
          showModal={showModal}
          setShowModal={setShowModal}
          openRegistryModal={openRegistryModal}
          id={id}
          isCarousel={carouselsName || carouselId}
        />
      </Conditional>
      <Conditional if={showRegistryModal}>
        <RegistryAddressModal
          showModal={showRegistryModal}
          setShowModal={setShowRegistryModal}
        />
      </Conditional>
      <Conditional
        if={
          isSessionInfoAvailable &&
          isItemStoreOnly &&
          !locationId &&
          !showModal &&
          !!carouselId
        }
      >
        <>
          <div
            className={`${classes['add-to-cart-wrap']} ${isFixed ? classes['fixed'] : ''}`}
          >
            <Link
              className={`${classes['add-to-cart-button']} ${isFaddingOut && classes['fade-out']}`}
              data-testid="locationModal"
              data-bs-target={`#findAnotherStorePDPModal${productId || id}`}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              <span>Add to cart</span>
            </Link>
          </div>
          <SelectStoreModal
            productId={productId || id}
            showSelectStoreModal={showSelectStoreModal}
            setShowSelectStoreModal={setShowSelectStoreModal}
            getInventory={getInventory}
            selectStoreError={'Please select a store to continue.'}
          />
        </>
      </Conditional>
    </>
  );
};

export default AddToCart;
