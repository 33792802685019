'use client';

import { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import Checkbox from '@next-app/components/elements/Checkbox/Checkbox';
import Conditional from '@next-app/components/Conditional';
import styles from './facets.module.scss';
import { IFacetsProps, IRefinement } from '../../../interface/PLP/index';
import Link from '@next-app/components/elements/Link/Link';
import {
  replaceNarrowBy,
  getFilterData,
  getCountFromResponseData,
  handleClick,
  getLinkProps,
  getClickLinkProps,
  isSRP,
} from './helper';
import { LocationRefinementMap } from '@next-app/constants/constants';
import { getUrl, trimURL } from '@next-app/utils/get-guided-data';
import { dyEvent } from '@next-app/utils/dy-event';

const Facets = (props: IFacetsProps) => {
  const {
    item,
    isCheckbox = true,
    getFilterLabel = () => {},
    setFilterCount = () => {},
    setUpdatedUrl = () => {},
    setIsFilter = () => {},
    isFilter = false,
    filterLabel = '',
    setFilterLabel = () => {},
    setSelectedFilter = () => {},
  } = props || {};
  const router = useRouter();
  const [viewMore, setViewMore] = useState(item?.keepExpanded || false);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const [searchPage, setSearchPage] = useState(false);

  useEffect(() => {
    setSearchPage(isSRP(window?.location?.href));
  }, []);

  const singleColumnFacets = [
    'Product Use',
    'Delivery Type',
    'Price',
    'Category',
    'Furniture Line',
    'Occasion',
    'Card Type',
    'Collection',
    'Color or Pattern',
    'Celebration or Season',
  ];
  const label = replaceNarrowBy(item?.name);
  const isSingleColumn = singleColumnFacets?.includes(label);

  const fetchData = async (selectedProductLink: string) => {
    const url = `${selectedProductLink}`;
    let navUrl = url;
    if (!navUrl.startsWith('/')) {
      navUrl = '/' + url;
    }

    const newUrl = getUrl(navUrl);
    try {
      const responseData = await makeFetchCall({
        endpoint: `${newUrl}`,
      });

      setSelectedFilter(responseData);

      const getCount = getCountFromResponseData(responseData);
      setFilterCount(getCount);
      return responseData;
    } catch (error) {
      console.error('Error fetching searching products:', error);
      return [];
    }
  };

  const handleLabel = (value: string) => {
    setIsFilter(value);
    setFilterLabel(value);
    getFilterLabel(value);
  };

  const itemsToDisplay = item?.refinements;

  const filterItem = getFilterData(item)?.join(', ');

  const handleDYEvent = (value: string) => {
    const properties = {
      dyType: 'filter-items-v1',
      filterType: replaceNarrowBy(item.name),
      filterStringValue: value,
    };
    dyEvent({ properties: properties, eventName: 'Filter Items' });
  };

  const handleCheckbox = (isMobile: boolean, link: string) => {
    if (isMobile) {
      fetchData(link);
      setUpdatedUrl(link);
    } else {
      router.push(trimURL(link));
    }
  };

  const twoColumnOptionElement: any = (
    data: any,
    isMobile: boolean = false,
  ) => {
    const midIndex: any = Math.ceil(data.length / 2);
    const part1: any = data.slice(0, midIndex);
    const part2: any = data.slice(midIndex);
    return (
      <div className={`d-flex ${isMobile ? styles.twoColumnWrapper : ''}`}>
        <div
          className={`${styles.leftSideColumn} ${searchPage ? styles['list-srp'] : ''}`}
        >
          <ul
            className={`${styles.list} ${searchPage ? styles['list-srp'] : ''}`}
          >
            <Conditional if={part1.length > 0}>
              <>
                {part1?.map((subItem: IRefinement, idx: number) => (
                  <li
                    key={`${filterLabel}-${idx}`}
                    className={styles.contentItem}
                  >
                    <Conditional if={subItem.count === 0}>
                      <div className={styles.disabled}>
                        <Conditional if={isCheckbox}>
                          <Checkbox
                            data-testid="checkbox-subItem"
                            label={''}
                            checked={subItem?.properties?.selected === 'true'}
                            onChange={() => {}}
                            disabled={subItem.count === 0}
                          />
                        </Conditional>
                        <Link
                          data-testid="subItem-label"
                          className={`${subItem.label === 'Sale Items' ? styles.last : ''}`}
                          dangerouslySetInnerHTML={{
                            __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                          }}
                        />
                      </div>
                    </Conditional>
                    <Conditional if={subItem.count !== 0}>
                      <div>
                        <Conditional if={isCheckbox}>
                          <div
                            onClick={() => {
                              handleDYEvent(
                                LocationRefinementMap[subItem.label] ||
                                  subItem.label ||
                                  '',
                              );
                            }}
                            className={`${styles.linkbox} ${subItem.label === 'Sale Items' ? styles.checkboxlast : ''}`}
                          >
                            <Link
                              className={styles.link}
                              data-redirect={false}
                              onClick={() => {
                                handleCheckbox(isMobile, subItem?.link);
                              }}
                            >
                              <Checkbox
                                data-testid="checkbox-no-subItem"
                                label={''}
                                checked={
                                  subItem?.properties?.selected === 'true'
                                }
                                onChange={() => {
                                  fetchData(subItem?.link);
                                  setUpdatedUrl(subItem?.link);
                                }}
                              />
                            </Link>
                            <Link
                              className={`${styles.checkboxlabel}`}
                              data-testid="checkboxLabel"
                              onClick={() => {
                                handleCheckbox(isMobile, subItem?.link);
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                              }}
                            />
                          </div>
                        </Conditional>
                        <Conditional
                          if={
                            !isCheckbox &&
                            subItem?.properties?.selected === 'true'
                          }
                        >
                          <div
                            onClick={() => {
                              handleDYEvent(
                                LocationRefinementMap[subItem.label] ||
                                  subItem.label ||
                                  '',
                              );
                            }}
                            className={styles.checkboxlabel}
                          >
                            <strong>
                              <Link
                                data-testid="checkboxLabel"
                                onClick={() => {
                                  handleCheckbox(isMobile, subItem?.link);
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                }}
                              />
                            </strong>
                          </div>
                        </Conditional>
                        <Conditional
                          if={
                            !isCheckbox &&
                            subItem?.properties?.selected !== 'true'
                          }
                        >
                          <div className={styles.checkboxlabel}>
                            <Link
                              data-testid="checkboxLabel"
                              className={styles.bold}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isMobile) {
                                  fetchData(subItem?.link);
                                  setUpdatedUrl(subItem?.link);
                                  handleDYEvent(
                                    LocationRefinementMap[subItem.label] ||
                                      subItem.label ||
                                      '',
                                  );
                                } else {
                                  router.push(subItem?.link);
                                }
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                              }}
                            />
                          </div>
                        </Conditional>
                      </div>
                    </Conditional>
                  </li>
                ))}
              </>
            </Conditional>
          </ul>
        </div>
        <div
          className={`${styles.rightSideColumn} ${searchPage ? styles['list-srp'] : ''}`}
        >
          <ul
            className={`${styles.list} ${searchPage ? styles['list-srp'] : ''}`}
          >
            <Conditional if={part2.length > 0}>
              <>
                {part2?.map((subItem: IRefinement, idx: number) => (
                  <li
                    key={`${filterLabel}-${idx}`}
                    className={styles.contentItem}
                  >
                    <Conditional if={subItem.count === 0}>
                      <div className={styles.disabled}>
                        <Conditional if={isCheckbox}>
                          <Checkbox
                            data-testid="checkbox-subItem"
                            label={''}
                            checked={subItem?.properties?.selected === 'true'}
                            onChange={() => {}}
                            disabled={subItem.count === 0}
                          />
                        </Conditional>
                        <Link
                          data-testid="subItem-label"
                          className={`${subItem.label === 'Sale Items' ? styles.last : ''}`}
                          dangerouslySetInnerHTML={{
                            __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                          }}
                        />
                      </div>
                    </Conditional>
                    <Conditional if={subItem.count !== 0}>
                      <div>
                        <Conditional if={isCheckbox}>
                          <div
                            onClick={() => {
                              handleDYEvent(
                                LocationRefinementMap[subItem.label] ||
                                  subItem.label ||
                                  '',
                              );
                            }}
                            className={`${styles.linkbox} ${subItem.label === 'Sale Items' ? styles.checkboxlast : ''}`}
                          >
                            <Link
                              data-redirect={false}
                              {...getLinkProps(
                                isMobile,
                                subItem?.link,
                                handleCheckbox,
                                subItem?.properties?.selected === 'true',
                              )}
                            >
                              <Checkbox
                                data-testid="checkbox-no-subItem"
                                label={''}
                                checked={
                                  subItem?.properties?.selected === 'true'
                                }
                                onChange={() => {
                                  fetchData(subItem?.link);
                                  setUpdatedUrl(subItem?.link);
                                }}
                              />
                            </Link>
                            <Link
                              className={styles.checkboxlabel}
                              data-testid="checkboxLabel"
                              onClick={() => {
                                handleCheckbox(isMobile, subItem?.link);
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                              }}
                            />
                          </div>
                        </Conditional>
                        <Conditional
                          if={
                            !isCheckbox &&
                            subItem?.properties?.selected === 'true'
                          }
                        >
                          <div
                            onClick={() => {
                              handleDYEvent(
                                LocationRefinementMap[subItem.label] ||
                                  subItem.label ||
                                  '',
                              );
                            }}
                            className={styles.checkboxlabel}
                          >
                            <strong>
                              <Link
                                data-testid="checkboxLabel"
                                onClick={() => {
                                  handleCheckbox(isMobile, subItem?.link);
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                }}
                              />
                            </strong>
                          </div>
                        </Conditional>
                        <Conditional
                          if={
                            !isCheckbox &&
                            subItem?.properties?.selected !== 'true'
                          }
                        >
                          <div className={styles.checkboxlabel}>
                            <Link
                              data-testid="checkboxLabel"
                              className={styles.bold}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isMobile) {
                                  fetchData(subItem?.link);
                                  setUpdatedUrl(subItem?.link);
                                  handleDYEvent(
                                    LocationRefinementMap[subItem.label] ||
                                      subItem.label ||
                                      '',
                                  );
                                } else {
                                  router.push(subItem?.link);
                                }
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                              }}
                            />
                          </div>
                        </Conditional>
                      </div>
                    </Conditional>
                  </li>
                ))}
              </>
            </Conditional>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <Conditional if={item?.refinements?.length > 0}>
      <div ref={parentRef}>
        <div className={`${styles.container} ${styles['nav-mobile']}`}>
          <h2 className={styles.title}>{item.name}</h2>
          <div className={styles.subContainer}>
            <Conditional if={!isSingleColumn}>
              {twoColumnOptionElement(item?.refinements, false)}
            </Conditional>
            <Conditional if={isSingleColumn}>
              <ul
                className={`${styles.list} ${isSingleColumn ? '' : styles.twocolumn}`}
              >
                <Conditional if={!!item?.mainCategoryDisplay}>
                  <li
                    onClick={() => {
                      handleDYEvent(item?.mainCategoryDisplay || '');
                    }}
                    className={styles.listheading}
                  >
                    <Link
                      className={styles.link}
                      {...getClickLinkProps(
                        item?.mainCategoryLink,
                        handleClick,
                        router,
                        true,
                      )}
                    >
                      <strong>{item?.mainCategoryDisplay}</strong>
                    </Link>
                  </li>
                </Conditional>

                <Conditional if={item?.refinements?.length > 0}>
                  <>
                    {itemsToDisplay
                      ?.slice(
                        0,
                        viewMore || !item?.showMoreLink
                          ? item.refinements.length
                          : 10,
                      )
                      ?.map((subItem: IRefinement, idx: number) => (
                        <li key={idx} className={styles['content-item']}>
                          <Conditional if={subItem.count === 0}>
                            <div className={styles.disabled}>
                              <Conditional if={isCheckbox}>
                                <Checkbox
                                  label={''}
                                  checked={
                                    subItem?.properties?.selected === 'true'
                                  }
                                  onChange={() => {}}
                                  disabled={subItem.count === 0}
                                />
                              </Conditional>
                              <Link
                                className={styles.label}
                                dangerouslySetInnerHTML={{
                                  __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                }}
                              />
                            </div>
                          </Conditional>

                          <Conditional if={subItem.count !== 0}>
                            <div>
                              <Conditional if={isCheckbox}>
                                <div
                                  onClick={() => {
                                    handleDYEvent(
                                      LocationRefinementMap[subItem.label] ||
                                        subItem.label,
                                    );
                                  }}
                                  className={`${styles['checkbox-flex']} ${searchPage ? styles['checkbox-flex-srp'] : ''}`}
                                >
                                  <Link
                                    {...getLinkProps(
                                      false,
                                      subItem?.link,
                                      handleCheckbox,
                                      subItem?.properties?.selected === 'true',
                                    )}
                                  >
                                    <Checkbox
                                      label={''}
                                      checked={
                                        subItem?.properties?.selected === 'true'
                                      }
                                      onChange={() =>
                                        router.push(subItem?.link)
                                      }
                                    />
                                  </Link>
                                  <Link
                                    className={`${subItem.label === 'Sale Items' ? styles.checkboxlast : ''} ${styles['link']}`}
                                    {...getLinkProps(
                                      false,
                                      subItem?.link,
                                      handleCheckbox,
                                      subItem?.properties?.selected === 'true',
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                    }}
                                  />
                                </div>
                              </Conditional>
                            </div>
                          </Conditional>

                          <Conditional
                            if={
                              !isCheckbox &&
                              subItem?.properties?.selected === 'true'
                            }
                          >
                            <div
                              onClick={() => {
                                handleDYEvent(
                                  LocationRefinementMap[subItem.label] ||
                                    subItem.label,
                                );
                              }}
                              className={styles.checkboxlabel}
                            >
                              <strong>
                                <Link
                                  data-testid="checkboxLabel"
                                  onClick={(event) =>
                                    handleClick(
                                      event,
                                      trimURL(subItem?.link),
                                      router,
                                    )
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                  }}
                                />
                              </strong>
                            </div>
                          </Conditional>

                          <Conditional
                            if={
                              !isCheckbox &&
                              subItem?.properties?.selected !== 'true'
                            }
                          >
                            <div
                              onClick={() => {
                                handleDYEvent(
                                  LocationRefinementMap[subItem.label] ||
                                    subItem.label,
                                );
                              }}
                              className={styles.checkboxlabel}
                            >
                              <Link
                                data-testid="checkboxLabel"
                                {...getClickLinkProps(
                                  subItem?.link,
                                  handleClick,
                                  router,
                                  subItem?.properties?.selected !== 'true',
                                )}
                                dangerouslySetInnerHTML={{
                                  __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                }}
                              />
                            </div>
                          </Conditional>
                        </li>
                      ))}
                  </>
                </Conditional>
              </ul>
            </Conditional>
            <Conditional
              if={item?.refinements?.length > 10 && !!item?.showMoreLink}
            >
              <button
                className={styles.viewButton}
                onClick={() => {
                  setViewMore(!viewMore);
                  if (viewMore && parentRef.current) {
                    parentRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                <span className={`ls-icon ${styles['icn-chevron-dwn']}`}></span>
                {viewMore ? 'View Less' : 'View More'}
              </button>
            </Conditional>
          </div>
        </div>

        <div className={styles.mobContainer}>
          <Conditional if={isFilter === replaceNarrowBy(item?.name)}>
            <div className={styles['filter-sub-container']}>
              <div
                data-testid="filter-sub-title"
                className={styles['filter-sub-title']}
                onClick={() => {
                  setIsFilter('All');
                  getFilterLabel('All');
                }}
              >
                <h2 className={styles['title']}>
                  {' '}
                  <span className={styles['left-arrow']}>
                    <span className={styles.mobArrow}></span>
                  </span>
                  {filterLabel}
                  <hr className={`${styles['hr-divider']} ${styles['mb0']}`} />
                </h2>
              </div>
              <Conditional if={!isSingleColumn}>
                {twoColumnOptionElement(item?.refinements, true)}
              </Conditional>
              <Conditional if={isSingleColumn}>
                <ul
                  className={`${styles.list} ${searchPage ? styles['list-srp'] : ''}`}
                >
                  <Conditional if={item?.refinements?.length > 0}>
                    <>
                      {item?.refinements?.map(
                        (subItem: IRefinement, idx: number) => (
                          <li
                            key={`${filterLabel}-${idx}`}
                            className={styles.contentItem}
                          >
                            <Conditional if={subItem.count === 0}>
                              <div className={styles.disabled}>
                                <Conditional if={isCheckbox}>
                                  <Checkbox
                                    data-testid="checkbox-subItem"
                                    label={''}
                                    checked={
                                      subItem?.properties?.selected === 'true'
                                    }
                                    onChange={() => {}}
                                    disabled={subItem.count === 0}
                                  />
                                </Conditional>
                                <Link
                                  data-testid="subItem-label"
                                  className={`${subItem.label === 'Sale Items' ? styles.last : ''}`}
                                  dangerouslySetInnerHTML={{
                                    __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                  }}
                                />
                              </div>
                            </Conditional>
                            <Conditional if={subItem.count !== 0}>
                              <div>
                                <Conditional if={isCheckbox}>
                                  <div
                                    onClick={() => {
                                      handleDYEvent(
                                        LocationRefinementMap[subItem.label] ||
                                          subItem.label ||
                                          '',
                                      );
                                    }}
                                    className={`${styles.linkbox} ${subItem.label === 'Sale Items' ? styles.checkboxlast : ''}`}
                                  >
                                    <Checkbox
                                      data-testid="checkbox-no-subItem"
                                      label={''}
                                      checked={
                                        subItem?.properties?.selected === 'true'
                                      }
                                      onChange={() => {
                                        fetchData(subItem?.link);
                                        setUpdatedUrl(subItem?.link);
                                      }}
                                    />
                                    <Link
                                      className={styles.checkboxlabel}
                                      data-testid="checkboxLabel"
                                      onClick={() => {
                                        fetchData(subItem?.link);
                                        setUpdatedUrl(subItem?.link);
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                      }}
                                    />
                                  </div>
                                </Conditional>
                                <Conditional
                                  if={
                                    !isCheckbox &&
                                    subItem?.properties?.selected === 'true'
                                  }
                                >
                                  <div
                                    onClick={() => {
                                      handleDYEvent(
                                        LocationRefinementMap[subItem.label] ||
                                          subItem.label ||
                                          '',
                                      );
                                    }}
                                    className={styles.checkboxlabel}
                                  >
                                    <strong>
                                      <Link
                                        data-testid="checkboxLabel"
                                        onClick={() => {
                                          fetchData(subItem?.link);
                                          setUpdatedUrl(subItem?.link);
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                        }}
                                      />
                                    </strong>
                                  </div>
                                </Conditional>
                                <Conditional
                                  if={
                                    !isCheckbox &&
                                    subItem?.properties?.selected !== 'true'
                                  }
                                >
                                  <div className={styles.checkboxlabel}>
                                    <Link
                                      data-testid="checkboxLabel"
                                      onClick={() => {
                                        fetchData(subItem?.link);
                                        setUpdatedUrl(subItem?.link);
                                        handleDYEvent(
                                          LocationRefinementMap[
                                            subItem.label
                                          ] ||
                                            subItem.label ||
                                            '',
                                        );
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: `${LocationRefinementMap[subItem.label] || subItem.label} (${subItem.count})`,
                                      }}
                                    />
                                  </div>
                                </Conditional>
                              </div>
                            </Conditional>
                          </li>
                        ),
                      )}
                    </>
                  </Conditional>
                </ul>
              </Conditional>
            </div>
          </Conditional>

          <Conditional if={isFilter !== replaceNarrowBy(item?.name)}>
            <h2
              data-testid="heading2"
              className={styles.title}
              onClick={() => {
                handleLabel(replaceNarrowBy(item?.name));
              }}
            >
              {replaceNarrowBy(item?.name)}{' '}
              <span className={styles.mobArrow}></span>
              <span className={styles.subheading}>
                <span
                  className={styles.selected}
                  dangerouslySetInnerHTML={{
                    __html: `${filterItem}`,
                  }}
                ></span>
              </span>
            </h2>
          </Conditional>
        </div>
      </div>
    </Conditional>
  );
};

export default Facets;
