import { IDisrupterTileProps } from '@next-app/interface/PLP';
import classes from './disrupter-tiles.module.scss';
import Link from '../elements/Link/Link';
import ResponsiveImage from '../elements/ResponsiveImage/ResponsiveImage';
import Conditional from '../Conditional';

const DisrupterTile = ({ contentItem, dataPlpToDtm }: IDisrupterTileProps) => {
  const {
    imageAltText,
    scene7Asset,
    scene7ImageParams,
    slotsToSpan,
    imageHref,
  } = contentItem;
  let colWidth = 'col-md-12';
  const imageURL = imageHref?.[0] || null;
  const adobeAttribute = JSON.stringify({
    vars: { eVar: 'eVar88' },
  });

  switch (slotsToSpan && slotsToSpan[0]) {
    case '0':
      colWidth = 'col-md-12';
      break;
    case '1':
      colWidth = 'col-md-3';
      break;

    case '2':
      colWidth = 'col-md-6';
      break;

    case '3':
      colWidth = 'col-md-9';
      break;

    default:
      colWidth = 'col-md-12';
      break;
  }

  const RenderedImage = (
    <ResponsiveImage
      fill={true}
      className={`${classes.image} position-static`}
      desktopImagePath={`/${scene7Asset}?${scene7ImageParams}`}
      unoptimized={true}
      altText={imageAltText}
      isFullUrl={true}
      data-activity-map={adobeAttribute}
    />
  );

  return (
    <div className={`${colWidth}`}>
      <div className={classes['image-wrapper']}>
        <Conditional if={imageURL}>
          <Link
            href={imageURL}
            data-analytics-attribute={JSON.stringify(dataPlpToDtm)}
            {...{ s_objectid: imageAltText }}
          >
            {RenderedImage}
          </Link>
        </Conditional>
        <Conditional if={!imageURL}>
          <Link
            data-bs-toggle="modal"
            data-bs-target="#contactForm-request"
            data-analytics-attribute={JSON.stringify(dataPlpToDtm)}
            {...{ s_objectid: imageAltText }}
          >
            {RenderedImage}
          </Link>
        </Conditional>
      </div>
    </div>
  );
};

export default DisrupterTile;
