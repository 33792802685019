import {
  SchemaMarkup,
  generateProductsListSchema,
} from '@next-app/utils/schemaGeneration';
import classes from './result-listing.module.scss';
import MobFilter from '../../Facets/MobFacets';
import ProductCards from '../../ProductCards/ProductCards';
import {
  IMobPageContentGuidedNavigation,
  IResultsListProps,
} from '@next-app/interface/PLP';
import { getInitData } from '@next-app/utils';
import Conditional from '@next-app/components/Conditional';

const ResultsList = async ({
  contentItem,
  guidedNavigation,
  view,
  metaTag,
  isGSAUser,
  isSRP,
}: IResultsListProps) => {
  const initResp = await getInitData('force-cache');
  const { sceneSevenImageUrlPrefix, scene7Env, scene7BaseURL } =
    initResp.initResponse;

  const {
    breadcrumbs: {
      refinementCrumbs = [],
      removeAllAction = {},
      searchCrumbs = [],
    } = {},
    selectedNavigationBreadcrumbs = {},
    totalNumRecs,
    recordType,
  } = contentItem;

  const productsListSchemaData = {
    id: 'productsList',
    data: generateProductsListSchema(contentItem.records),
  };

  let availability = 0;

  guidedNavigation?.forEach((navItem: IMobPageContentGuidedNavigation) => {
    const availabilityFacet = navItem?.facets?.find(
      (item) => item?.name === 'Narrow by Availability',
    );
    const count = availabilityFacet?.refinements?.find(
      (item) => item?.label === 'inStoreSku',
    )?.count;
    if (count !== undefined) {
      availability += count;
    }
  });

  return (
    <div
      className={`${classes['plp-resultList']} ${refinementCrumbs?.length > 0 ? 'filters-active' : ''}`}
    >
      {!isSRP && <SchemaMarkup {...productsListSchemaData} />}
      <Conditional if={recordType === 'sku'}>
        <MobFilter
          guidedNavigation={guidedNavigation}
          filterCount={refinementCrumbs?.length}
          mainDimension={contentItem?.mainDimension}
          clearAllURL={removeAllAction?.navigationState}
          metaTag={metaTag}
          selectedNavigationBreadcrumbs={selectedNavigationBreadcrumbs}
          isSRP={isSRP}
          searchCrumbs={searchCrumbs}
          totalNumRecs={totalNumRecs}
        />
      </Conditional>
      <ProductCards
        view={view}
        isGSAUser={isGSAUser}
        contentItem={contentItem}
        filtersLength={refinementCrumbs?.length}
        availabilityCount={availability}
        imgBaseUrl={`${scene7BaseURL}${sceneSevenImageUrlPrefix}${scene7Env}`}
        clearAllURL={removeAllAction?.navigationState}
        refinementCrumbs={refinementCrumbs}
        mainDimension={contentItem?.mainDimension}
      />
    </div>
  );
};

export default ResultsList;
