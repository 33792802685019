'use client';
import React, { useEffect, useState } from 'react';
import Card from '../../shared/PageContent/AccountMenuLink/StoreCard';
import { useSelector } from 'react-redux';
import { Stores } from '@next-app/interface/StoreList';
import { getStoresByZip } from '@next-app/utils/get-store-data';
import {
  selectDynSess,
  selectSelectedStore,
} from '@next-app/lib/features/InitSelectors';

interface ISelectStoreModal {
  productId?: string;
  getInventory: Function;
  showSelectStoreModal: boolean;
  setShowSelectStoreModal: Function;
  selectedSku?: string;
  selectStoreError?: string;
}

const SelectStoreModal = (props: ISelectStoreModal) => {
  const {
    productId = '',
    getInventory,
    showSelectStoreModal,
    setShowSelectStoreModal,
    selectedSku,
    selectStoreError,
  } = props;
  const [zipCode, setZipcode] = useState<string>('');
  const [data, setData] = useState([]);
  const [storeUserMessage, setStoreUserMessage] = useState({});
  const { init } = useSelector((state: any) => state.init);
  const selectedStore = useSelector(selectSelectedStore);
  const dynSessConfNumber = useSelector(selectDynSess);
  const { initResponse: { internalAPI = '' } = {} } = init || {};

  const handlePDPStoreModal = async (storeData: Stores[]) => {
    const apiToFetched = storeData?.map((item) => {
      return getInventory(item.id);
    });
    const res = await Promise.all(apiToFetched);

    storeData?.map((item, index) => {
      const skuIds = res?.[index]?.skuIds;
      const skuData = skuIds.find((obj: any) =>
        selectedSku ? obj.skuId == selectedSku : obj.skuId == productId,
      );
      return (item.storePickupDetails = skuData
        ? {
            isInStorePickupEligible: skuData?.isInStorePickupEligible,
            stockLevel: skuData?.stockLevel,
          }
        : { isInStorePickupEligible: false, stockLevel: 0 });
    });

    storeData.sort((a, b) => {
      if (
        a?.storePickupDetails?.stockLevel === 'Available' &&
        b?.storePickupDetails?.stockLevel !== 'Available'
      ) {
        return -1;
      } else if (
        a?.storePickupDetails?.stockLevel !== 'Available' &&
        b?.storePickupDetails?.stockLevel === 'Available'
      ) {
        return 1;
      }
      return 0;
    });

    return storeData;
  };

  let cardProps: any = {
    zipCode,
    data,
    isMobileView: true,
    storeUserMessage,
  };

  const handleStoreData = async () => {
    const storeData = await getStoresByZip(
      {
        longitude: selectedStore.longitude,
        latitude: selectedStore.latitude,
        internalAPI,
        dynSessConfNumber: dynSessConfNumber,
      },
      selectedStore.postalCode,
    );
    if (storeData) {
      const data: any = await handlePDPStoreModal(storeData?.stores);
      setStoreUserMessage(storeData?.userMessages);
      setData(data);
    }
  };

  useEffect(() => {
    if (showSelectStoreModal) {
      handleStoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelectStoreModal]);

  return (
    <Card
      portalId={`findAnotherStorePDPModal${productId}`}
      setZipcode={setZipcode}
      setData={setData}
      setStoreUserMessage={setStoreUserMessage}
      setShow={setShowSelectStoreModal}
      zipCode={zipCode}
      data={data}
      plpModalTitle={true}
      blueHeader={true}
      handlePDPStoreModal={handlePDPStoreModal}
      storeUserMessage={storeUserMessage}
      isPDPModal={true}
      selectStoreError={selectStoreError}
      {...cardProps}
    />
  );
};

export default React.memo(SelectStoreModal);
