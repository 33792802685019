'use client';

import React, { useEffect, useState } from 'react';
import { Content } from '@next-app/interface/Common';
import { useSelector } from 'react-redux';
import classes from './social-icons.module.scss';
import GenericImage from '../../../elements/GenericImage/GenericImage';
import Conditional from '@next-app/components/Conditional';

interface HTMLProps {
  contentItem: Content;
}
interface InitState {
  init: {
    init: {
      initResponse: {
        sceneSevenImageUrlPrefix: string;
        scene7BaseURL: string;
        scene7Env: string;
      };
    };
  };
}

const SocialIcons = ({ contentItem }: HTMLProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileCheck = /iPhone|Android/i.test(navigator.userAgent);
    setIsMobile(mobileCheck);
  }, []);

  const { init } = useSelector((state: InitState) => state.init);

  const [facebookIcon, setFacebookIcon] = useState('');
  const [instagramIcon, setInstagramIcon] = useState('');
  const [pintristIcon, setPintristIcon] = useState('');
  const [youtubeIcon, setYoutubeIcon] = useState('');
  const [linkedinIcon, setLinkedinIcon] = useState('');

  const {
    fbicn,
    igicn,
    pnicn,
    yticn,
    lnicn,
    igurl,
    fburl,
    pnurl,
    yturl,
    lnurl,
    igapp,
    fbapp,
    pnapp,
    ytapp,
    lnapp,
  } = contentItem;

  useEffect(() => {
    if (init.initResponse) {
      const { sceneSevenImageUrlPrefix, scene7BaseURL, scene7Env } =
        init.initResponse;
      const facebookIconurl =
        scene7BaseURL + sceneSevenImageUrlPrefix + scene7Env + '/' + fbicn;
      setFacebookIcon(facebookIconurl);
      const instagramIconurl =
        scene7BaseURL + sceneSevenImageUrlPrefix + scene7Env + '/' + igicn;
      setInstagramIcon(instagramIconurl);
      const pintristIconurl =
        scene7BaseURL + sceneSevenImageUrlPrefix + scene7Env + '/' + pnicn;
      setPintristIcon(pintristIconurl);
      const youtubeIconurl =
        scene7BaseURL + sceneSevenImageUrlPrefix + scene7Env + '/' + yticn;
      setYoutubeIcon(youtubeIconurl);
      const linkedinIconurl =
        scene7BaseURL + sceneSevenImageUrlPrefix + scene7Env + '/' + lnicn;
      setLinkedinIcon(linkedinIconurl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init.initResponse]);

  const hasImageData =
    facebookIcon ||
    instagramIcon ||
    pintristIcon ||
    youtubeIcon ||
    linkedinIcon;
  return (
    <div className={classes['social-icons-container']}>
      <Conditional if={hasImageData.length > 0}>
        <div className="container p-12">
          <div className="row">
            <div
              className={`col-md-12 social-icon-text d-flex justify-content-center ${classes['text-terms']}`}
            >
              <p>
                <strong>Follow us on social media</strong> for great craft
                ideas, teaching tips, news and more!
              </p>
            </div>
            <div className={`footer-social-icons ${classes['footer-social']}`}>
              <a
                data-redirect={false}
                href={isMobile ? igapp : igurl}
                id="in-link"
                target="_blank"
              >
                <GenericImage
                  src={instagramIcon}
                  width={44}
                  height={44}
                  alt="Instagram Icon"
                ></GenericImage>
              </a>
              <a
                data-redirect={false}
                href={isMobile ? fbapp : fburl}
                id="fb-link"
                target="_blank"
              >
                <GenericImage
                  src={facebookIcon}
                  width={44}
                  height={44}
                  alt="Facebook Icon"
                ></GenericImage>
              </a>
              <a
                data-redirect={false}
                href={isMobile ? pnapp : pnurl}
                id="pin-link"
                target="_blank"
              >
                <GenericImage
                  src={pintristIcon}
                  width={44}
                  height={44}
                  alt="Pin Icon"
                ></GenericImage>
              </a>
              <a
                data-redirect={false}
                href={isMobile ? ytapp : yturl}
                id="youtube-link"
                target="_blank"
              >
                <GenericImage
                  src={youtubeIcon}
                  width={44}
                  height={44}
                  alt="youtube Icon"
                ></GenericImage>
              </a>
              <a
                data-redirect={false}
                href={isMobile ? lnapp : lnurl}
                id="linked-link"
                target="_blank"
              >
                <GenericImage
                  src={linkedinIcon}
                  width={44}
                  height={44}
                  alt="linked Icon"
                ></GenericImage>
              </a>
            </div>
          </div>
        </div>
      </Conditional>
    </div>
  );
};

export default SocialIcons;
