'use client';
import { useState } from 'react';
import HTMLContentModal from '@next-app/components/elements/HTMLContentModal/HTMLContentModal';
import Link from '@next-app/components/elements/Link/Link';
import ResponsiveImageClient from '@next-app/components/elements/ResponsiveImage/ResponsiveImageClient';
import Conditional from '@next-app/components/Conditional';
const BannerSeeDetailsModal = (props: any) => {
  const [modalUrl, setModalUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  function handleClick() {
    setModalTitle(props.title);
    setModalUrl(props.url);
  }

  const renderImage = () => (
    <ResponsiveImageClient
      mobileImagePath={props.mobileImageURL}
      tabletImagePath={props.imageURL}
      desktopImagePath={props.imageURL}
      altText={props.imageAlt || ''}
      width={1500}
      height={170}
      isFullUrl={props.isFullUrl}
      lazyload={false}
      loading="eager"
    />
  );

  return (
    <>
      <Conditional if={props.imgOpenModal}>
        <>
          <Link
            className="display-block no-decor"
            href=""
            s_objectID={props.sObjectId}
            {...(props.url && {
              'data-bs-target': '#BannerSeeDetailModal',
              'data-bs-toggle': 'modal',
            })}
            onClick={() => {
              handleClick();
            }}
          >
            {renderImage()}
          </Link>

          <HTMLContentModal
            url={modalUrl}
            modalTitle={modalTitle}
            modalId="BannerSeeDetailModal"
          />
        </>
      </Conditional>
      <Conditional if={props.imageLink && !props.imgOpenModal}>
        <>
          <Link
            className="display-block no-decor"
            href={props.imageLink}
            s_objectid={props.sObjectId}
          >
            {renderImage()}
          </Link>
        </>
      </Conditional>

      <Conditional if={!props.imageLink && !props.imgOpenModal}>
        {renderImage()}
      </Conditional>
    </>
  );
};

export default BannerSeeDetailsModal;
