import { IPageContentButton } from '@next-app/interface/Common';
import Link from '@next-app/components/elements/Link/Link';
import ResponsiveImage from '@next-app/components/elements/ResponsiveImage/ResponsiveImage';
import { getUniqueKey, findComponent } from '@next-app/utils';
import Heading from '@next-app/components/elements/Heading/Heading';
import classes from './full-width-image-with-buttons.module.scss';
import { FullWidthImageWithButtonsProps } from '@next-app/interface/PageContent';
import Conditional from '@next-app/components/Conditional';

const FullWidthImageWithButtons = (props: FullWidthImageWithButtonsProps) => {
  const { contentItem } = props;

  const desktopImgUrl = `${contentItem.webImgAsset}?${contentItem.webImgPreset}`;
  const tabImgUrl = `${contentItem.tabletImgAsset || contentItem.webImgAsset}?${contentItem.tabletImgPreset || contentItem.webImgPreset}`;
  const mobileImgUrl = `${contentItem.moibileImgAsset || contentItem.tabletImgAsset || contentItem.webImgAsset}?${contentItem.moibileImgPreset || contentItem.tabletImgPreset || contentItem.webImgPreset}`;

  const webUrl = contentItem.webURL || '#';
  const tabletUrl = contentItem.tabletURL || webUrl;
  const moibileUrl = contentItem.moibileURL || tabletUrl;
  const ComponentList = contentItem?.buttons?.map(
    (item: IPageContentButton, key: number) => {
      const Component = findComponent(item);
      if (!Component) return null;
      return (
        <Component
          contentItem={item}
          index={key}
          key={getUniqueKey(key, item.name)}
        />
      );
    },
  );

  const restComponent = ComponentList.filter((_, i) => i !== 0);
  const isFullUrl = mobileImgUrl.indexOf('https://') < 0;

  const elemAttributes = {
    s_objectid: contentItem?.s_objectId || '',
  };

  return (
    <Conditional if={contentItem.enabled}>
      <div
        className={`${classes['take-it-out-section']} take-it-out-section-wrapper ${contentItem.fullWidth ? 'full-width-container' : 'page-container'}`}
        {...{ s_regionid: contentItem?.s_regionId || '' }}
      >
        <Link
          tabIndex={0}
          className="d-block no-decor"
          href={moibileUrl}
          {...elemAttributes}
        >
          <ResponsiveImage
            className="position-static h-auto"
            mobileImagePath={mobileImgUrl}
            tabletImagePath={tabImgUrl}
            desktopImagePath={desktopImgUrl}
            altText={
              contentItem.webAltText ||
              contentItem.tabletAltText ||
              contentItem.moibileAltText ||
              ''
            }
            lazyload={contentItem.lazyload ? true : false}
            fill={true}
            isFullUrl={isFullUrl}
            imgAssetFolder={contentItem.imgAssetFolder}
          />
        </Link>

        <div className={classes.heroText}>
          <Heading tagName="2" customClass={classes['section-heading']}>
            {contentItem.title}
          </Heading>
          <p>{contentItem.subTitle}</p>
          <div className={classes['ll-btn-container']}>{ComponentList[0]}</div>
        </div>
        <div className={classes.heroBtns}>{restComponent}</div>
      </div>
    </Conditional>
  );
};

export default FullWidthImageWithButtons;
