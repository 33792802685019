import { replaceNarrowBy } from '@next-app/components/shared/Facets/helper';
import { IMobPageContentGuidedNavigation } from '@next-app/interface/PLP';
import { cleanPathname } from './generatePLPath';
import { addTrailingSlash } from './common-utils';

export const hasSelectedRefinement = (
  navigation: IMobPageContentGuidedNavigation,
  isFilter: string,
) => {
  if (isFilter?.trim() === 'Price') {
    return navigation?.facets
      ?.find((el) => replaceNarrowBy(el?.name) === isFilter)
      ?.contents[0]?.refinements?.some(
        (el) => el.properties.selected === 'true',
      );
  } else {
    return (
      navigation?.facets
        ?.find((el) => replaceNarrowBy(el?.name) === isFilter)
        ?.refinements?.some((el) => el.properties.selected === 'true') || false
    );
  }
};

export const removeDynamicIdsFromUrl = (
  url: string,
  navigation: IMobPageContentGuidedNavigation,
  isFilter: string,
) => {
  if (isFilter === 'Category') {
    const categoryURL = navigation?.facets?.find(
      (el) => replaceNarrowBy(el?.name) === isFilter,
    );
    let navUrl =
      categoryURL?.mainCategoryLink || categoryURL?.assortmentURL || '';

    return `${navUrl}` || url;
  }
  const dynamicIds =
    isFilter?.trim() === 'Price'
      ? navigation?.facets
          ?.find((el) => replaceNarrowBy(el?.name) === isFilter)
          ?.contents[0]?.refinements?.filter(
            (items) => items?.properties?.selected === 'true',
          )
          ?.map((sub) => sub?.properties?.dimValId)
      : navigation?.facets
          ?.find((el) => replaceNarrowBy(el?.name) === isFilter)
          ?.refinements?.filter(
            (items) => items?.properties?.selected === 'true',
          )
          ?.map((sub) => sub?.properties?.dimValId);

  const urlParts = url.split('/N/');
  const idsPart = (urlParts?.length && urlParts[1].split('/')[0]) || '';

  const ids = idsPart?.split('+');

  const filteredIds = ids.filter((id, index: number) => {
    if (index === 0) return true;

    return !dynamicIds?.includes(id);
  });

  urlParts[1] = filteredIds.join('+') + urlParts[1].substring(idsPart?.length);
  return urlParts.join('/N/');
};

export const removeDynamicIdsFromUrlSRP = (
  url: string,
  navigation: IMobPageContentGuidedNavigation,
  isFilter: string,
): string => {
  let dynamicIds;
  if (isFilter?.trim() === 'Price') {
    dynamicIds = navigation?.facets
      ?.find((el) => replaceNarrowBy(el?.name) === isFilter)
      ?.contents[0]?.refinements?.filter(
        (items) => items?.properties?.selected === 'true',
      )
      ?.map((sub) => sub?.properties?.dimValId);
  } else {
    dynamicIds = navigation?.facets
      ?.find((el) => replaceNarrowBy(el?.name) === isFilter)
      ?.refinements?.filter((items) => items?.properties?.selected === 'true')
      ?.map((sub) => sub?.properties?.dimValId);
  }

  const urlParts = url.split('/N/');
  const idsPart = (urlParts?.length && urlParts[1].split('/')[0]) || '';

  const ids = idsPart?.split('+');

  const filteredIds = ids.filter((id) => {
    return !dynamicIds?.includes(id);
  });

  urlParts[1] = filteredIds.join('+') + urlParts[1].substring(idsPart?.length);
  if (filteredIds.length) {
    return urlParts.join('/N/');
  } else {
    return urlParts.join('');
  }
};

export const handleAllClear = (data: IMobPageContentGuidedNavigation) => {
  if (!data || !data.facets) {
    return false;
  }

  return data.facets.some(
    (facet) =>
      facet.refinements?.some(
        (refinement) => refinement.properties.selected === 'true',
      ) ||
      facet.contents?.some((content) =>
        content.refinements?.some(
          (refinement) => refinement.properties.selected === 'true',
        ),
      ),
  );
};

export const addSlash = (link: string = '') => {
  return link.startsWith('/') ? link : `/${link}`;
};

export const trimURL = (link: string = '') => {
  link = addSlash(link);
  link = addTrailingSlash(link);
  return cleanPathname(link);
};

export const getUrl = (url: string) => {
  const navUrl = url.split('?');
  if (navUrl && navUrl.length && navUrl.length > 1) {
    if (navUrl[0].endsWith('/json/')) {
      return url;
    }
    if (navUrl[0].endsWith('/json')) {
      return `${navUrl[0]}/?${navUrl[1]}`;
    }
    if (!navUrl[0].endsWith('/json')) {
      return navUrl[0].endsWith('/')
        ? `${navUrl[0]}json/?${navUrl[1]}`
        : `${navUrl[0]}/json/?${navUrl[1]}`;
    }
  } else {
    if (url.endsWith('/json/')) {
      return url;
    }

    if (url.endsWith('/json')) {
      return `${url}/`;
    }
    if (!url.endsWith('/json')) {
      return url.endsWith('/') ? `${url}json/` : `${url}/json/`;
    }
  }
};
