/* eslint-disable no-unused-vars */
import constants from '@next-app/constants/constants';
import { ResponseData } from '@next-app/interface/PLP/ProductListPage';
import { PageContentFacets, IRefinement } from '@next-app/interface/PLP/index';
import { LocationRefinementMap } from '@next-app/constants/constants';
import {
  trackAnalyticsEvent,
  getAdobeAttribute,
} from '@next-app/script/RouteChangeListener';
import { setActivityMapLink } from '@next-app/utils/set-adobe-activity-map';
import { trimURL } from '@next-app/utils/get-guided-data';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

export const replaceNarrowBy = (label: string) => {
  return label?.replace(constants?.name?.narrowByLabel, '').trim();
};

export const getCountFromResponseData = (responseData: ResponseData) => {
  const countFromMainContent =
    responseData?.mainContent &&
    responseData?.mainContent.length &&
    responseData?.mainContent[0]?.productListingInfo &&
    responseData?.mainContent[0]?.productListingInfo[0]?.mainDimension?.count;

  if (countFromMainContent !== undefined) {
    return countFromMainContent;
  }

  const countFromContents =
    responseData?.contents &&
    responseData?.contents.length &&
    responseData.contents[0]?.mainContent &&
    responseData.contents[0]?.mainContent[0]?.contents &&
    responseData.contents[0]?.mainContent[0]?.contents[0]?.productListingInfo &&
    responseData.contents[0]?.mainContent[0]?.contents[0]?.productListingInfo[0]
      ?.mainDimension?.count;

  if (countFromContents !== undefined) {
    return countFromContents;
  }

  const srpCountFromContent =
    responseData.contents?.[0]?.mainContent?.[0]?.contents?.[0]
      ?.productListingInfo?.[0]?.resultTotalCount;

  return srpCountFromContent;
};

export const getFactsFilterCount = (responseData: ResponseData) => {
  return (
    responseData?.mainContent?.[0]?.productListingInfo?.[0]?.mainDimension
      ?.count ||
    responseData?.contents?.[0]?.mainContent?.[0]?.contents?.[0]
      ?.productListingInfo?.[0]?.resultTotalCount ||
    responseData?.contents?.[0]?.mainContent?.[0]?.contents?.[0]
      ?.productListingInfo?.[0]?.mainDimension?.count
  );
};

export const getFilterData = (item: PageContentFacets) => {
  return item?.refinements
    ?.filter((subItem: IRefinement) => subItem?.properties?.selected === 'true')
    .map((el) => {
      return LocationRefinementMap[el.label] || el.label;
    });
};

export const newLocationRefinement = (item: PageContentFacets) => {
  if (
    item?.dimensionName !== 'skuShipType' ||
    item?.refinements?.length !== 1
  ) {
    return item?.refinements;
  }

  const { label } = item.refinements[0];

  if (label === 'shipOrderSku') {
    return [
      ...item.refinements,
      {
        navigationState: '',
        count: 0,
        link: '',
        label: 'inStoreSku',
        properties: {
          'DGraph.Spec': 'inStoreSku',
          dimValId: '',
          selected: 'false',
        },
      },
    ];
  }

  if (label === 'inStoreSku') {
    return [
      {
        navigationState: '',
        count: 0,
        link: '',
        label: 'shipOrderSku',
        properties: {
          'DGraph.Spec': 'shipOrderSku',
          dimValId: '',
          selected: 'false',
        },
      },
      ...item.refinements,
    ];
  }

  return item?.refinements;
};

export const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement>,
  link: string = '',
  router: AppRouterInstance,
) => {
  const nativeEvent = event.nativeEvent;
  trackAnalyticsEvent(nativeEvent);

  // Get adobe attribute if available
  const closestAnchorTag = event.target as HTMLElement;
  const activityAdobeMap = closestAnchorTag
    ?.closest('[data-activity-map]')
    ?.getAttribute('data-activity-map');

  if (activityAdobeMap) {
    const adobeAttribute = getAdobeAttribute(activityAdobeMap || '{}');

    if (adobeAttribute && Object.keys(adobeAttribute).length > 0) {
      const { data } = adobeAttribute;
      // Assuming you have a setActivityMapLink function defined in your project
      setActivityMapLink(closestAnchorTag, data);
    }
  }
  router.push(link);
};

export const getLinkProps = (
  isMobile: boolean,
  link: string,
  // eslint-disable-next-line no-unused-vars
  handleClick: (isMobile: boolean, link: string) => void,
  checked: boolean = false,
) => {
  if (!isMobile && !link?.includes('+') && !checked) {
    return { href: trimURL(link) };
  }
  return {
    onClick: () => {
      handleClick(isMobile, link);
    },
  };
};

export const getClickLinkProps = (
  link: string | undefined,
  handleClick: (
    event: React.MouseEvent<HTMLAnchorElement>,
    url: string,
    router: AppRouterInstance,
  ) => void,
  router: AppRouterInstance,
  checked: boolean = false,
) => {
  if (link?.includes('+') && checked) {
    return {
      onClick: (event: React.MouseEvent<HTMLAnchorElement>) =>
        handleClick(event, trimURL(link), router),
    };
  }
  return { href: trimURL(link) };
};

export const createHref = (
  category: IRefinement,
  categories: PageContentFacets,
  subCatSelectedData: IRefinement | undefined,
) => {
  return category?.properties['selected'] === 'true' && !subCatSelectedData
    ? trimURL(categories?.assortmentURL || category?.link)
    : trimURL(category?.link);
};

export const getSubCatProps = (
  category: IRefinement,
  categories: PageContentFacets,
  subCatSelectedData: IRefinement | undefined,
  // eslint-disable-next-line no-unused-vars
  handleDYEvent: (label: string, link: string) => void,
) => {
  const hrefLink = createHref(category, categories, subCatSelectedData);
  if (
    categories?.assortmentURL?.includes('+') ||
    category?.link?.includes('+')
  ) {
    return {
      onClick: () => handleDYEvent(category?.label, hrefLink),
    };
  }
  return {
    href: hrefLink,
    onClick: () => handleDYEvent(category?.label, hrefLink),
  };
};

export const isSRP = (location: string) => {
  const isSearchP =
    (location?.includes('Ntt=') && location?.includes('/search/')) || false;
  return isSearchP;
};
