import { IPLPParams } from '@next-app/interface/PLP/ProductListPage';
import { getCookieServer } from '@next-app/serverActions';

function checkPathAvailability(path: string, listOfPath: string[]) {
  return listOfPath.some((item) => item.startsWith(path));
}

export async function createPath(
  listOfPath: string[] = [],
  isPdp: boolean = false,
) {
  if (!isPdp) {
    if (!checkPathAvailability('sort-', listOfPath)) {
      const val = (await getCookieServer('sort')) || 'best';
      listOfPath.push(`sort-${val}`);
    }
    if (!checkPathAvailability('num-', listOfPath)) {
      const val = (await getCookieServer('num')) || 96;
      listOfPath.push(`num-${val}`);
    }
  }

  return listOfPath;
}

export async function generatePLPPath(
  params: IPLPParams,
  isSale?: boolean,
  isGSA?: boolean,
  storeId: string = '',
  isPdp: boolean = false,
  Endeca_user_segments?: string,
  Endeca_date?: string,
) {
  if (isSale) {
    const decodedFilters = params?.sale?.map((filter: string) =>
      decodeURIComponent(filter),
    );
    const pathArray = await createPath(decodedFilters, isPdp);
    if (decodedFilters) {
      return `/sale/${pathArray?.join('/')}${storeId ? '/loc-' + storeId : ''}/json/${isGSA ? 'gsa/' : ''}`;
    } else {
      return `/sale/${pathArray?.join('/')}${storeId ? '/loc-' + storeId : ''}/json/${isGSA ? 'gsa/' : ''}`;
    }
  }
  const decodedFilters = params?.productFilter?.map((filter: string) =>
    decodeURIComponent(filter),
  );
  const pathArray = await createPath(decodedFilters, isPdp);
  const endecaPath = getEndecaPath(Endeca_user_segments, Endeca_date);
  return `/products/${params.category}/${pathArray?.join('/')}${!isPdp ? `${storeId ? '/loc-' + storeId : ''}/json/${isGSA ? 'gsa/' : ''}` : ''}${endecaPath}`;
}

/**
 * creates query string based on endeca arguments
 * @param Endeca_user_segments
 * @param Endeca_date
 * @returns
 */
export function getEndecaPath(
  Endeca_user_segments?: string,
  Endeca_date?: string,
) {
  let endecaPath = '';
  let decodedEndecaDate = decodeURIComponent(Endeca_date || '');

  // Check if Endeca_user_segments is provided
  if (Endeca_user_segments) {
    // Check if Endeca_date is also provided
    if (Endeca_date) {
      // Both Endeca_user_segments and Endeca_date are provided
      endecaPath = `?Endeca_user_segments=${Endeca_user_segments}&Endeca_date=${decodedEndecaDate}`;
    } else {
      // Only Endeca_user_segments is provided
      endecaPath = `?Endeca_user_segments=${Endeca_user_segments}`;
    }
  } else if (Endeca_date) {
    // Only Endeca_date is provided
    endecaPath = `?Endeca_date=${decodedEndecaDate}`;
  }
  return endecaPath;
}

export function normalizePathname(pathname: string) {
  if (pathname !== '/' && pathname.endsWith('/')) {
    return pathname.slice(0, -1);
  }
  return pathname;
}

export function cleanPathname(pathname: string) {
  const segmentsToRemove = ['page', 'sort', 'num', 'loc', 'gsa'];
  let segments = pathname.split('/');
  segments = segments.filter((segment) => {
    return !segmentsToRemove.some((keyword) =>
      segment.startsWith(keyword + '-'),
    );
  });
  pathname = segments.join('/');
  return normalizePathname(pathname + '/');
}
