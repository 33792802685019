import { isNotNullOrUndefinedorEmpty } from '@next-app/utils/common-utils';

/**
 * This function will return true if
 * this component receives different image source for different viewport
 */
export const checkForArtDirectionProblem = (
  arrayOfSourceImagePaths: (string | undefined)[] = [],
): boolean => {
  const count =
    arrayOfSourceImagePaths.filter((imagePath: string | undefined) =>
      isNotNullOrUndefinedorEmpty(imagePath),
    ).length || 0;
  return count > 1;
};
