import { findComponent } from '@next-app/utils';
import {
  HeaderBlockContent,
  HeaderLeft,
  HeaderRight,
} from '@next-app/interface/Header';
import Hamburger from '../../Hamburger/Hamburger';
import classes from './section-header.module.scss';

interface SectionHeaderProps {
  contentItem: HeaderBlockContent;
}

type DynamicComponentType = React.ComponentType<{
  contentItem: HeaderBlockContent;
}>;

export default async function SectionHeader(props: SectionHeaderProps) {
  const HeaderMenuComponentList = props?.contentItem?.headerLeft?.map(
    (item: HeaderLeft, index: number) => {
      if (item['@type'] === 'PageContent-SectionHTMLTag') {
        const DynamicComponent = findComponent(item) as DynamicComponentType;
        return <DynamicComponent contentItem={item} key={'menuItem' + index} />;
      }
    },
  );

  const HeaderLeftComponentList = props?.contentItem?.headerLeft?.map(
    (item: HeaderLeft, index: number) => {
      if (item['@type'] !== 'PageContent-SectionHTMLTag') {
        const DynamicComponent = findComponent(item) as DynamicComponentType;
        return <DynamicComponent contentItem={item} key={'menuItem' + index} />;
      }
    },
  );

  const HeaderRightComponents = props?.contentItem?.headerRight?.map(
    (item: HeaderRight, index: number) => {
      const DynamicComponent = findComponent(item) as DynamicComponentType;
      return <DynamicComponent contentItem={item} key={'r' + index} />;
    },
  );

  const attributes = {
    s_regionid: 'Header - Main',
  };

  return (
    <header
      className={`${classes['header-wrapper']} header-wrapper hover new-header f-enabled`}
      {...attributes}
    >
      <div className="container">
        <div className={classes['header-your-store-section']}>
          {HeaderMenuComponentList}
        </div>
        <div className="row tight" id="header-row">
          <div
            className={`${classes['header-left']} text-left col-3 col-lg-3 header-left hideHeaderElem`}
          >
            <Hamburger />
            <span className="d-none d-lg-block">{HeaderLeftComponentList}</span>
          </div>

          <div
            className={`${classes['header-center']}  col-6 text-center d-lg-none hideHeaderElem`}
          >
            {HeaderLeftComponentList}
          </div>

          <div
            className={`${classes['header-right']} header-right col-3 col-lg-9`}
          >
            {HeaderRightComponents}
          </div>
        </div>
      </div>
    </header>
  );
}
