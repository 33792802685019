import classes from './product-card.module.scss';
import Conditional from '@next-app/components/Conditional';
import { IProductPriceProps } from '@next-app/interface/PLP';
import { compareTwoValue } from '../ProductDetails/helper';
import { getPrice } from '@next-app/utils/PDPHelper';

const ProductPrice = ({ product, isGSAUser }: IProductPriceProps) => {
  const isGSA = isGSAUser === 'true';

  const {
    attributes: {
      'product.saleMinPrice': productSaleMinPrice = 0,
      'product.saleMaxPrice': productSaleMaxPrice = 0,
      'product.minPrice': productMinPrice = 0,
      'product.maxPrice': productMaxPrice = 0,
      'product.onSale': onSale = [],
      'product.activeSkus': activeSku = [],
      'product.isGC': isGiftCard = [],
      'product.gcPriceRange': gcPriceRange = [],
      'product.isGSAPrice': isGSAPrice = [],
    } = {},
  } = product || {};

  const renderPriceRange = (
    minPrice: string,
    maxPrice: string,
    sku: boolean,
  ) => (
    <>
      {getPrice(Number(minPrice))}
      <Conditional if={sku && compareTwoValue(minPrice, maxPrice)}>
        <span> - {getPrice(Number(maxPrice))}</span>
      </Conditional>
    </>
  );

  const getProductPrice = () => {
    const sku = activeSku?.[0]?.split(',') || [];
    const isOnSale = onSale?.[0] === 'true';

    return (
      <>
        <Conditional if={isGSA && isGSAPrice?.[0] === '1'}>
          <span className={classes.gsaUser}>
            {renderPriceRange(
              productSaleMinPrice,
              productSaleMaxPrice,
              sku.length > 0,
            )}{' '}
            {'GSA'}
          </span>
        </Conditional>
        <Conditional if={!isGSA || isGSAPrice?.[0] !== '1'}>
          <>
            <Conditional if={isOnSale}>
              <>
                <span className={classes.sale}>
                  {renderPriceRange(
                    productSaleMinPrice,
                    productSaleMaxPrice,
                    sku.length > 1,
                  )}
                </span>
                <span className={classes.reg}>
                  reg.{' '}
                  {renderPriceRange(
                    productMinPrice,
                    productMaxPrice,
                    sku.length > 1,
                  )}
                </span>
              </>
            </Conditional>
            <Conditional if={!isOnSale}>
              <span>
                {renderPriceRange(
                  productMinPrice,
                  productMaxPrice,
                  sku.length > 1,
                )}
              </span>
            </Conditional>
          </>
        </Conditional>
      </>
    );
  };

  return (
    <div className={classes.price}>
      <Conditional if={isGiftCard?.[0] === 'true'}>{gcPriceRange}</Conditional>
      <Conditional if={isGiftCard?.[0] !== 'true'}>
        {getProductPrice()}
      </Conditional>
    </div>
  );
};

export default ProductPrice;
