'use client';

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const ErrorDTMEvent = () => {
  const { init, sessionInfo } = useSelector((state: any) => state.init);

  const isEventFired = useRef(false);
  const hasData =
    Object.keys(sessionInfo).length > 0 &&
    Object.keys(init?.initResponse).length > 0;

  // Only re-run the effect when sessionInfo or init.initResponse changes
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.sendDTMEvent &&
      hasData &&
      !isEventFired.current
    ) {
      const { sessionInfo: info } = sessionInfo;
      const { profile } = info;

      const isStore = Object.keys(profile?.selectedStore).length > 0;

      const data = {
        storeSelected: isStore
          ? `${profile?.selectedStore?.city}, ${profile?.selectedStore?.state}`
          : '',
        cartCount: info?.cartInfo?.cartQty,
        userShopFor: profile?.userShopFor || '',
        email: profile?.email || '',
        profileId: profile?.id || '',
        md5Email: profile?.md5Email || '',
        isGSAUser: profile?.gsaUser,
        recognized: profile?.loginStatus === 'recognized',
        orderId: info?.cartInfo?.cartId,
        llmAPIVersion: init?.initResponse?.internalAPI,
        isFurniture: info?.furniture,
        name: 'pageLoadData',
        pageTitle: 'pageNotFound',
        pageName: 'pageNotFound',
        pageUrl: '/pageNotFound',
        currentUrl: '/pageNotFound',
        rewardsUserStatus: info?.rewardsCustomer?.tierInfo?.currentTier,
        securityStatus: profile?.securityStatus,
        findingMethod: '',
        billingAddress: {
          country: profile?.selectedStore?.city,
          postalCode: profile?.selectedStore?.postalCode,
          state: profile?.selectedStore?.state,
          firstName: profile?.firstName,
          lastName: profile?.lastName,
        },
      };

      if (window.sendDTMEvent) {
        window.sendDTMEvent(data);
      }
      isEventFired.current = true;
    }
  }, [sessionInfo, init?.initResponse]); // Re-run only when sessionInfo or initResponse changes

  return null;
};

export default ErrorDTMEvent;
