import React from 'react';
import { getUniqueKey } from '@next-app/utils';
import {
  IGuidedNavigationProps,
  PageContentFacets,
} from '@next-app/interface/PLP';
import LocationRefinementMenu from '@next-app/components/shared/PageContent/LocationRefinementMenu/LocationRefinementMenu';
import NonCategoryNavRefinementMenu from '@next-app/components/shared/PageContent/NonCategoryNavRefinementMenu/NonCategoryNavRefinementMenu';
import RefinementMenu from '@next-app/components/shared/PageContent/RefinementMenu/RefinementMenu';
import SimpleRefinementMenu from '@next-app/components/shared/PageContent/SimpleRefinementMenu/SimpleRefinementMenu';
import CategoryNavRefinementMenu from '@next-app/components/shared/PageContent/CategoryNavRefinementMenu/CategoryNavRefinementMenu';
import FilteredCategoryRefinementMenu from '@next-app/components/shared/PageContent/FilteredCategoryRefinementMenu/FilteredCategoryRefinementMenu';

const GuidedNavigation = (props: IGuidedNavigationProps) => {
  let { facets: list = [] } = props?.contentItem || {};
  if (list?.length === 0) {
    list = props?.contentItem?.contents?.[0]?.facets || [];
  }

  const {
    getFilterLabel,
    setFilterCount,
    setUpdatedUrl,
    setIsFilter,
    isFilter,
    setFilterLabel,
    filterLabel,
    setSelectedFilter,
    assortmentTitle,
  } = props;

  const OneColComponentList = list?.map(
    (item: PageContentFacets, index: number) => {
      const type = item?.['@type'];
      return (() => {
        switch (type) {
          case 'PageContent-LocationRefinementMenu': {
            return <LocationRefinementMenu contentItem={item} />;
          }
          case 'PageContent-NonCategoryNavRefinementMenu': {
            return (
              <NonCategoryNavRefinementMenu
                contentItem={item}
                getFilterLabel={getFilterLabel}
                key={getUniqueKey(index, item.name)}
                setFilterCount={setFilterCount}
                setUpdatedUrl={setUpdatedUrl}
                setIsFilter={setIsFilter}
                isFilter={isFilter}
                setFilterLabel={setFilterLabel}
                filterLabel={filterLabel}
                setSelectedFilter={setSelectedFilter}
                assortmentTitle={assortmentTitle}
              />
            );
          }

          case 'PageContent-RefinementMenu': {
            return (
              <RefinementMenu
                contentItem={item}
                getFilterLabel={getFilterLabel}
                key={getUniqueKey(index, item.name)}
                setFilterCount={setFilterCount}
                setUpdatedUrl={setUpdatedUrl}
                setIsFilter={setIsFilter}
                isFilter={isFilter}
                setFilterLabel={setFilterLabel}
                filterLabel={filterLabel}
                setSelectedFilter={setSelectedFilter}
              />
            );
          }
          case 'PageContent-SimpleRefinementMenu': {
            return (
              <SimpleRefinementMenu
                contentItem={item}
                getFilterLabel={getFilterLabel}
                key={getUniqueKey(index, item.name)}
                setFilterCount={setFilterCount}
                setUpdatedUrl={setUpdatedUrl}
                setIsFilter={setIsFilter}
                isFilter={isFilter}
                setFilterLabel={setFilterLabel}
                filterLabel={filterLabel}
                setSelectedFilter={setSelectedFilter}
              />
            );
          }
          case 'PageContent-RefinementMenuCollection': {
            return (
              <RefinementMenu
                contentItem={item?.contents?.[0] || item}
                key={getUniqueKey(index, item.name)}
                setFilterCount={setFilterCount}
                setUpdatedUrl={setUpdatedUrl}
                getFilterLabel={getFilterLabel}
                setIsFilter={setIsFilter}
                isFilter={isFilter}
                setFilterLabel={setFilterLabel}
                filterLabel={filterLabel}
                setSelectedFilter={setSelectedFilter}
              />
            );
          }
          case 'PageContent-CategoryNavRefinementMenu': {
            return (
              <CategoryNavRefinementMenu
                contentItem={item}
                getFilterLabel={getFilterLabel}
                key={getUniqueKey(index, item.name)}
                setFilterCount={setFilterCount}
                setUpdatedUrl={setUpdatedUrl}
                setIsFilter={setIsFilter}
                isFilter={isFilter}
                setFilterLabel={setFilterLabel}
                filterLabel={filterLabel}
                setSelectedFilter={setSelectedFilter}
                assortmentTitle={assortmentTitle}
              />
            );
          }
          case 'PageContent-FilteredCategoryRefinementMenu': {
            return (
              <FilteredCategoryRefinementMenu
                contentItem={item}
                getFilterLabel={getFilterLabel}
                key={getUniqueKey(index, item.name)}
                setFilterCount={setFilterCount}
                setUpdatedUrl={setUpdatedUrl}
                setIsFilter={setIsFilter}
                isFilter={isFilter}
                setFilterLabel={setFilterLabel}
                filterLabel={filterLabel}
                setSelectedFilter={setSelectedFilter}
                assortmentTitle={assortmentTitle}
              />
            );
          }
        }
      })();
    },
  );

  return OneColComponentList;
};

export default GuidedNavigation;
