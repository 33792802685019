'use client';

import React, { useEffect, useState } from 'react';
import classes from './section-footer.module.scss';
import { footerAppNames } from '@next-app/config/elementNameConfig';
import Conditional from '@next-app/components/Conditional';
import mobileClass from '@next-app/utils/get-mobile-type';
import Image from 'next/image';
import apple from '../../../../../public/assets/images/apple-icon.svg';
import googlePlay from '../../../../../public/assets/images/GooglePlay.svg';
import appQRCode from '../../../../../public/assets/images/footer_qr_code.png';

const SectionFooterApp = () => {
  const [deviceClassName, setDeviceClassName] = useState<string | undefined>(
    '',
  );

  useEffect(() => {
    const handleResize = () => {
      const className = mobileClass();
      setDeviceClassName(className);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderStoreBadge = () => (
    <div
      className={`d-flex gap-3 ${deviceClassName} ${classes['app-store-badge-wrap']}`}
    >
      <Conditional
        if={
          deviceClassName === 'apple-device' ||
          deviceClassName === 'desktop-device'
        }
      >
        <a
          data-redirect={false}
          className="apple"
          href="https://apps.apple.com/us/app/lakeshore-learning-materials/id1589186464"
          target="_blank"
        >
          <Image
            fill={true}
            className="position-static"
            alt="Download on the Apple App Store"
            src={apple}
            loading="lazy"
          />
        </a>
      </Conditional>
      <Conditional
        if={
          deviceClassName === 'android-device' ||
          deviceClassName === 'desktop-device'
        }
      >
        <a
          data-redirect={false}
          className="android"
          href="https://play.google.com/store/apps/details?id=com.lakeshorelearning.mobileapp&utm_campaign=web.b2c.footer"
          target="_blank"
        >
          <Image
            fill={true}
            className="position-static"
            alt="Get it on Google Play"
            loading="lazy"
            src={googlePlay}
          />
        </a>
      </Conditional>
    </div>
  );

  return (
    <div className={`${classes['footer-get-the-app-wrap']}`}>
      <div className={`${classes['footer-get-the-app']}`}>
        <h3>{footerAppNames.getTheApp}</h3>
        <div className={`${classes['get-app-content']}`}>
          <div className={`${classes['qr-code']}`}>
            <span className="qr-code-img">
              <Image
                width={73}
                height={73}
                alt="Scan the QR code to download our app."
                loading="lazy"
                src={appQRCode}
              />
            </span>
          </div>
          <div className={`d-flex flex-column ${classes['app-store-badge']}`}>
            <span className={`${classes['qr-code-text']}`}>
              {' '}
              {footerAppNames.scanQrCode}
            </span>
            {renderStoreBadge()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFooterApp;
